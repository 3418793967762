import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
    paragraph: {
        fontSize: '16px',
    },
    anchor: {
        fontSize: '12px',
    },
}));

/**
 * JSX for No Default Payment Method
 *
 * @returns {JSX.Element}              The component markup.
 */

const NoDefaultPaymentMethod = () => {
    const classes = useStyles();

    return (
        <Box>
            <p className={classes.paragraph}>You do not appear to have a default payment method set up.</p>
            <p className={classes.paragraph}>Click the link below to find out more about updating your payment method.</p>
            <p className={classes.paragraph}>
                <a className={classes.anchor} href="https://auhelpcentre.corelogic.com.au/hc/en-au/articles/4403711082767-How-do-I-update-my-payment-details-" target="_blank" rel="noopener noreferrer">
                    How do I update my payment method?
                </a>
            </p>
        </Box>
    );
};

export default NoDefaultPaymentMethod;
  