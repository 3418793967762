import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import progressLoaderStyles from '../../styles/ProgressLoader';

const ProgressLoader = (props) => {
    const { text, circularProgressSize } = props;
    const { progressWrapper } = progressLoaderStyles(props);
    return (
        <div className={progressWrapper}>
            <h2>
                <CircularProgress size={circularProgressSize ? circularProgressSize : 40}/>
                <br/>
                <div>{ text ? text : 'Loading...' }</div>
            </h2>
        </div>
    );
};

ProgressLoader.propTypes = {
    text: PropTypes.string.isRequired,
    circularProgressSize: PropTypes.number,
};

export default ProgressLoader;