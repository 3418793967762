import {
    GET_PAYMENT_METHOD_REQUEST,
    GET_PAYMENT_METHOD_SUCCESS,
    GET_PAYMENT_METHOD_FAILURE,
    GET_ACCOUNT_AND_CONTACT_REQUEST,
    GET_ACCOUNT_AND_CONTACT_SUCCESS,
    GET_ACCOUNT_AND_CONTACT_FAILURE,
    UPDATE_ACCOUNT_REQUEST,
    UPDATE_ACCOUNT_SUCCESS,
    UPDATE_ACCOUNT_FAILURE,
    UPDATE_CONTACT_REQUEST,
    UPDATE_CONTACT_SUCCESS,
    UPDATE_CONTACT_FAILURE,
    CREATE_ACTIVITY_REQUEST,
    CREATE_ACTIVITY_SUCCESS,
    CREATE_ACTIVITY_FAILURE,
    RESET_CUSTOMER,
    ADD_ATTACHMENT_REQUEST,
    ADD_ATTACHMENT_SUCCESS,
    ADD_ATTACHMENT_FAILURE,
    GET_INVOICES_REQUEST,
    GET_INVOICES_SUCCESS,
    GET_INVOICES_FAILURE,
    CREATE_TOKEN_REQUEST,
    CREATE_TOKEN_SUCCESS,
    CREATE_TOKEN_FAILURE,
    SET_PERMISSIONS_SUCCESS,
    SET_PAYMENT_CHANGE_INFO_SUCCESS,
    RESET_TOKEN_REQUEST,
} from '../constants/reducer';

import { getPrimaryContactEmailAddress, getPaymentContactEmailAddress } from '../services/accountService';
import { replaceSpaces } from '../services/helper';

const initialCustomerState = {
    loader: false,
    success: false,
    failed: false,
    customer: null,
    accountLoader: false,
    contactLoader: false,
    accountUpdateSuccess: true,
    contactUpdateSuccess: true,
    activityLoader: false,
    activity: {},
    attachmentLoader: false,
    payments: {},
    getPaymentsLoader: false,
    paymentsLoader: false,
    paymentsUpdateSuccess: true,
    invoices: [],
    invoicesLoader: false,
    tokenLoader: false,
    account: {},
    setPermissions: false,
    cta: '',
    tokenRequestFailed: false,
};

const customer = (state = initialCustomerState, action) => {

    //This is to ensure immutability of state
    let newState = {...state};

    switch (action.type) {
    case GET_PAYMENT_METHOD_REQUEST:
        newState.getPaymentsLoader = true;
        newState.payments = {};
        return newState;
    case GET_PAYMENT_METHOD_SUCCESS:
        newState.getPaymentsLoader = false;
        newState.payments = action.data;
        return newState;
    case GET_PAYMENT_METHOD_FAILURE:
        newState.getPaymentsLoader = false;
        return newState;
    case GET_INVOICES_REQUEST:
        newState.invoicesLoader = true;
        newState.invoices = [];
        return newState;
    case GET_INVOICES_SUCCESS:
        newState.invoicesLoader = false;
        newState.invoices = action.data;
        return newState;
    case GET_INVOICES_FAILURE:
        newState.invoicesLoader = false;
        return newState;
    case GET_ACCOUNT_AND_CONTACT_REQUEST:
        newState.loader = true;
        newState.failed = false;
        newState.success = false;
        return newState;
    case GET_ACCOUNT_AND_CONTACT_SUCCESS:
        newState.loader = false;
        newState.failed = false;
        newState.success = true;
        if (action.data?.account) {
            action.data.account['primaryContactEmailAddress'] = getPrimaryContactEmailAddress(action.data?.account);
            action.data.account['paymentContactEmailAddress'] = getPaymentContactEmailAddress(action.data?.account);
            action.data.account['phoneAreaCode'] = replaceSpaces(action.data.account['phoneAreaCode']);
            action.data.account['phoneNumber'] = replaceSpaces(action.data.account['phoneNumber']);
        }
        newState.customer = action.data;
        return newState;
    case GET_ACCOUNT_AND_CONTACT_FAILURE:
        newState.loader = false;
        newState.failed = true;
        newState.success = false;
        return newState;
    case UPDATE_ACCOUNT_REQUEST:
        newState.accountLoader = true;
        return newState;
    case UPDATE_ACCOUNT_SUCCESS:
        newState.accountLoader = false;
        newState.accountUpdateSuccess = true;
        return newState;
    case UPDATE_ACCOUNT_FAILURE:
        newState.accountLoader = false;
        newState.accountUpdateSuccess = false;
        return newState;
    case UPDATE_CONTACT_REQUEST:
        newState.accountLoader = true;
        newState.contactLoader = true;
        return newState;
    case UPDATE_CONTACT_SUCCESS:
        newState.accountLoader = false;
        newState.contactLoader = false;
        newState.contactUpdateSuccess = true;
        if (newState.customer)
            newState.customer.contact = action.data;

        return newState;
    case UPDATE_CONTACT_FAILURE:
        newState.accountLoader = false;
        newState.contactUpdateSuccess = false;
        newState.contactLoader = false;
        return newState;
    case CREATE_ACTIVITY_REQUEST:
        newState.activityLoader = true;
        return newState;
    case CREATE_ACTIVITY_SUCCESS:
        newState.activityLoader = false;
        newState.activity = action.data;
        return newState;
    case CREATE_ACTIVITY_FAILURE:
        newState.activityLoader = false;
        return newState;
    case RESET_CUSTOMER:
        newState = initialCustomerState;
        return newState;
    case ADD_ATTACHMENT_REQUEST:
        newState.attachmentLoader = true;
        return newState;
    case ADD_ATTACHMENT_SUCCESS:
        newState.attachmentLoader = false;
        newState.activity = {};
        return newState;
    case ADD_ATTACHMENT_FAILURE:
        newState.attachmentLoader = false;
        return newState;
    case CREATE_TOKEN_REQUEST:
        newState.tokenLoader = true;
        newState.tokenRequestFailed = false;
        return newState;
    case CREATE_TOKEN_SUCCESS:
        newState.tokenLoader = false;
        newState.tokenRequestFailed = false;
        if (action.data)
            window.location.assign(action.data?.redirectUrl);

        return newState;
    case CREATE_TOKEN_FAILURE:
        newState.tokenRequestFailed = true;
        newState.tokenLoader = false;
        return newState;
    case SET_PERMISSIONS_SUCCESS:
        newState.setPermissions = true;
        return newState;
    case SET_PAYMENT_CHANGE_INFO_SUCCESS:
        newState.cta = action.data;
        return newState;
    case RESET_TOKEN_REQUEST:
        newState.tokenRequestFailed = false;
        return newState;
    default:
        return state;
    }
};

export default customer;
