import { makeStyles } from '@material-ui/core/styles';

import CorelogicLogoBrand from '../../image/CorelogicLogoBrand.svg';
import CorelogicLogoBrandMobile from '../../image/CorelogicLogoBrandMobile.svg';

const accountSelectionPageStyles = makeStyles((theme) => ({
    pageContainer: {
        minHeight: 'calc(100vh - 245px)',
        paddingTop: '100px',
    },
    heading: {
        fontWeight: '500',
        fontSize: '20px',
        letterSpacing: '0.15px',
    },
    inactive: {
        color: 'red',
    },
    list: {
        maxWidth: '320px',
        margin: '0 auto',
        border: '1.5px solid #E5E5E5',
        borderBottom: 'none',
        '&:first-child': {
            borderTop: '1px solid #f2f2f2',
        },
        '&:last-child': {
            boxShadow: '0 4px 2px -2px #898989',
            marginBottom: '30px',
        },
        padding: '0',
    },
    chevron: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    primaryText: {
        color: '#000',
    },
    secondaryText: {
        color: '#0000008a',
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
        padding: '0',
    },
    logoBrand: {
        [theme.breakpoints.down('xs')]: {
            backgroundImage: `url(${CorelogicLogoBrandMobile})`,
            backgroundSize: '100px',
            width: '95px !important',
            height: '80px !important',
        },
        [theme.breakpoints.down('sm')]: {
            backgroundImage: `url(${CorelogicLogoBrandMobile})`,
            width: '150px',
            height: '125px',
        },
        [theme.breakpoints.up('md')]: {
            backgroundImage: `url(${CorelogicLogoBrand})`,
        },
        [theme.breakpoints.up('lg')]: {
            backgroundImage: `url(${CorelogicLogoBrand})`,
        },
        width: '350px',
        height: '165px',
        backgroundRepeat: 'no-repeat',
    },
    logoLine: {
        [theme.breakpoints.down('xs')]: {
            margin: '.5rem !important',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '1rem',
        },
        margin: '2rem',
    },
    logoText: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '24px !important',
            lineHeight: '54px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '36px',
            lineHeight: '54px',
        },
        fontFamily: 'Source Sans Pro',
        fontWeight: '600',
        fontSize: '45px',
        lineHeight: '42px',
    },
    
}));


export default accountSelectionPageStyles;
