import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import { LoginAction } from '../../../controller/actions';
import { SegmentAnalyticsService } from '../../../controller/services';
import { grey } from '@material-ui/core/colors';

//IMAGES
import CorelogicLogo from '../../../image/CorelogicLogo.svg';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

export default function Header() {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const login = useSelector((state) => state.login);
    const customer = useSelector((state) => state.customer);

    const handleLogout = () => {
        setAnchorEl(null);
        dispatch(new LoginAction().userLogout());
        !new SegmentAnalyticsService().trackUserSession('Logged out', login, customer?.customer?.account);
        !new SegmentAnalyticsService().analyticsLogoutUser();
    };

    function redirectToURL(link) {
        window.location.href = link;
    }

    function getPasswordChangeUrl() {
        !new SegmentAnalyticsService().trackAction('Change Password', login, customer.customer.account);
        dispatch(new LoginAction().redirectToChangePasswordPage());
    }
    
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className="navbar-inverse">
                <Toolbar style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        <img 
                            alt="Brand" 
                            id="corelogic-logo"
                            className="logo-brand"
                            onClick={() => {
                                redirectToURL('https://www.corelogic.com.au')
                                ;
                            }}
                            src={CorelogicLogo}/>
                    </div>
                    {
                        login.isUserLoggedIn &&
                        <div>
                            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                <AccountBoxOutlinedIcon
                                    fontSize="large"
                                    color="inherit"
                                    style={{ color: grey[50] }}
                                />
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={getPasswordChangeUrl}>Change Password</MenuItem>
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </Menu>
                        </div>
                    }
                </Toolbar>
            </AppBar>
        </div>
    );
}
