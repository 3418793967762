import React, { useState, useEffect } from 'react';
import { CardHeader, Card, CardContent, Button, CardActions,
    Grid, Typography, FormControl, InputLabel, FormHelperText,
    CircularProgress, Snackbar, Tooltip, FormGroup, FormControlLabel, Checkbox, Box } from '@material-ui/core';
import DomainIcon from '@material-ui/icons/Domain';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';

import { AddressAction, CustomerAction } from '../../../../controller/actions';
import {
    isInvoiceAddress,
    isReportAddress,
    isReportAndInvoiceAddress,
    getAddressFromReducer,
    getSingleLineAddress,
    getAddressesToBeUpdated,
    getAddressesToBeCreated,
    getAddressesToBeDeleted,
    validateReportAddress,
    validateInvoiceAddress,
    hasOnlyAddressChanges,
    hasAddressChanges,
    getConfirmChangesModalTitle,
    getConfirmChangesModalBody,
    isAddressValid,
    primaryAndPaymentContactIsSame,
    getFieldsInfoTitle,
    getFieldsInfoBody,
    getContactsToBeUpdated,
} from '../../../../controller/services/accountService';
import { getAccountDetailsPermissions } from '../../../../controller/services/userService';
import { showLabelSkelton, readOnlyTextSkelton, textFieldSkeleton, addressFieldSkeleton } from '../../reusables/Skeletons';
import { VALIDATOR, SECTION, UPDATE_ACCOUNT, UPDATE_ADDRESS, UPDATE_CONTACT, CREATE_ADDRESS, FIELD_INFO_TYPES } from '../../../../controller/constants/accountDetails';
import AddressInput from '../../reusables/AddressInput';
import { SegmentAnalyticsService } from '../../../../controller/services';
import {
    formatBusinessNumber,
    hasNoValidationErrors,
    validateText,
    isValidPhoneNumber,
    objectIsEmpty,
} from '../../../../controller/services/helper';
import BootstrapInput from '../../general/bootstrapInput';
import Alert from '../../general/alert';
import ModalComponent from '../../general/modal';

const useStyles = makeStyles(() => ({
    card: {
        padding: 0,
        marginBottom: 20,
        overflow: 'auto',
    },
    cardHeader: {
        backgroundColor: '#eee',
        fontSize: 20,
        textAlign: 'left',
    },
    cardContent: {
        padding: 20,
    },
    grid: {
        flexGrow: 1,
    },
    asterisk: {
        color: '#E92300',
    },
    organisationPhoneLabel: {
        marginBottom: '24px',
    },
    invoiceAddressCheckbox: {
        '& span': {
            fontSize: '13px !important',
            color: '#38424A',
        },
        padding: '0px 0px 7px 18px !important',
        textAlign: 'left',
        pointerEvents: 'none',
    },
    invoiceAddressCheckboxReadOnly: {
        textTransform: 'none',
        fontSize: '13px !important',
        color: '#38424A',
        '& svg': {
            margin: '-4px 12px -4px 0px',
            fontSize: '18px',
        },
    },
    invoiceAddressLabel: {
        '& label': {
            marginTop: '11px',
        },
    },
    customTooltip: {
        fontSize: '1.15rem',
        margin: '1px 1px 1px 4px',
    },
    spinner: {
        marginLeft: '10px',
    },
    label: {
        minWidth: '420px',
        textAlign: 'left',
    },
    typography: {
        paddingTop: '36px',
    },
    phoneNumberAndAreaCode: {
        textAlign: 'left',
    },
    areaCode: {
        borderTopRightRadius: 'unset !important',
        borderBottomRightRadius: 'unset !important',
    },
    phoneNumber: {
        borderTopLeftRadius: 'unset !important',
        borderBottomLeftRadius: 'unset !important',
    },
}));

AccountDetails.propTypes = {
    getAccountInfo: PropTypes.func.isRequired,
};

export default function AccountDetails(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const customer = useSelector((state) => state.customer);
    const addressReducer = useSelector((state) => state.address);
    const user = useSelector((state) => state.login);
    const [account, setAccount] = useState({});
    const [reportAddress, setReportAddress] = useState(null);
    const [invoiceAddress, setInvoiceAddress] = useState(null);
    const [reportAddressSuggestions, setReportAddressSuggestions] = useState([]);
    const [invoiceAddressSuggestions, setInvoiceAddressSuggestions] = useState([]);
    const [singleLineReportAddress, setSingleLineReportAddress] = useState('');
    const [singleLineInvoiceAddress, setSingleLineInvoiceAddress] = useState('');
    const [isAccountValid, setIsAccountValid] = useState(false);
    const [reportAddressSelected, setReportAddressSelected] = useState(true);
    const [invoiceAddressSelected, setInvoiceAddressSelected] = useState(true);
    const [validator, setValidator] = useState({...VALIDATOR});
    const [showAlert, setShowAlert] = useState(false);
    const [updatedFields, setUpdatedFields] = useState({});
    const [resetComponent, setResetComponent] = useState(false);
    const [invoiceAddressSameAsReport, setInvoiceAddressSameAsReport] = useState(false);
    const [addressActionsInProgress, setAddressActionsInProgress] = useState(false);
    const [confirmChangesModal, setConfirmChangesModal] = useState(false);
    const [fieldsInfoModal, setFieldsInfoModal] = useState(false);
    const [fieldsInfoType, setFieldsInfoType] = useState(false);
    const [permissions, setPermissions] = useState({});
    const [updatesProcessed, setUpdatesProcessed] = useState(null);

    const loading = customer?.loader || objectIsEmpty(account);
    const businessNumber = account?.organizationDEOXRegisteredBusinessNumberC?.replace(/\s+/g, '') || '';

    const getPrimaryContactTooltip = () => {               
        if (primaryAndPaymentContactIsSame(account)) 
            return 'This contact will be used for account, billing and financial related correspondence.  \u00A0For example invoices and advice of price changes will be sent to this contact.';
        else
            return 'This contact will be used for account related correspondence. \u00A0For example advice of price changes will be sent to this contact.';
    }; 

    const setSingleLineAddresses = (addressType, addressValue) => {
        if (addressType === 'reportAddress') setSingleLineReportAddress(addressValue);
        if (addressType === 'invoiceAddress') setSingleLineInvoiceAddress(addressValue);
    };

    const toggleConfirmChangesModal = () => setConfirmChangesModal(!confirmChangesModal);
    const toggleFieldsInfoModal = () => setFieldsInfoModal(!fieldsInfoModal);

    const onSaveChangesClick = () => {
        if (validateAccount() && validateAddresses())
            toggleConfirmChangesModal();
    };

    // Called when "Save Changes" button is clicked
    const handleConfirmChanges = () => {
        toggleConfirmChangesModal();
        update();
    };

    // Called when Field Info Links are clicked
    const showFieldInfoModal = (fieldType) => {
        !new SegmentAnalyticsService().trackField('Want to change', fieldType);
        setFieldsInfoType(fieldType);
        toggleFieldsInfoModal();
    };

    // Closes the Alert Messages (Success/Warning/Failure Alerts)
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') return;

        setShowAlert(false);
    };

    // Handler for all the input elements except the Address input fields
    function handleChange(event) {
        const { name, value } = event.target;
        const tempUdatedFields = {...updatedFields};
        setAccount((prevState) => ({...prevState, [name]: value }));

        const tempValidator = {...validator};
        if (tempValidator[name].required || (value !== null && value.length > 0))
            tempValidator[name].error = !validateText(name, value);
        else
            tempValidator[name].error = false;

        // If the entered value is the same as pre-existing value OR (entered value is empty string AND there was no pre-existing value)
        if (value === customer.customer.account[name] || (value?.length === 0 && !customer.customer.account[name]))
            delete tempUdatedFields[name];
        else
            tempUdatedFields[name] = value;

        // If the Payment Contact Email Address is editable and is empty then set required and error validator as True
        if (name === 'paymentContactEmailAddress' && value.length === 0)
            tempValidator[name].error = tempValidator[name].required = true;

        const isValid = hasNoValidationErrors({...tempValidator});

        setIsAccountValid(isValid);
        setUpdatedFields(tempUdatedFields);
        setValidator(tempValidator);
    }

    // Handler for Address input field when the Address is updated manually.
    const handleManualAddressChange = (event, addressType) => {
        const { name, value } = event.target;
        const tempUpdatedFields = {...updatedFields};
        const tempValidator = {...validator};
        const addressValidator = tempValidator[addressType];
        const addressPreUpdate = getAddressFromReducer(addressType, customer);

        // Validate the entered value and set the error to either true or false
        if (addressValidator[name].required || (value !== null && value.length > 0))
            addressValidator[name].error = !validateText(name, value);
        else
            addressValidator[name].error = false;

        const isValid = isAddressValid(addressValidator);

        // When the user updates the address for the very first time the tempUpdatedFields[addressType] will be undefined. Therefore set it to an empty object.
        if (typeof(tempUpdatedFields[addressType]) === 'undefined') tempUpdatedFields[addressType] = {};

        if (isValid && value.length > 0) {
            tempUpdatedFields[addressType] = {
                ...tempUpdatedFields[addressType],
                [name]: value,
            };
            // If the entered value matches the original address value (pre-updated value), then delete that entry from the tempUpdatedFields object
            if (addressPreUpdate &&
                addressPreUpdate[name] === value &&
                tempUpdatedFields[addressType])
                delete tempUpdatedFields[addressType][name];
        } else
            delete tempUpdatedFields[addressType][name];

        // If the tempUpdatedFields[addressType] is an empty object then delete it, since there are no changes made to the address.
        if (objectIsEmpty(tempUpdatedFields[addressType])) delete tempUpdatedFields[addressType];

        // Set all the related state variables
        setIsAccountValid(isValid);
        setUpdatedFields(tempUpdatedFields);
        setValidator(tempValidator);
        if (addressType === 'reportAddress') setReportAddress((prevState) => ({...prevState, [name]: value }));
        else if (addressType === 'invoiceAddress') setInvoiceAddress((prevState) => ({...prevState, [name]: value }));
    };

    // Handler for Address input field when the Address is updated through Autocomplete.
    function handleAutocompleteAddressChange(event, address, addressType) {
        const { name, value } = event.target;
        const tempValidator = {...validator};

        // If the entered value matches the existing single line address then close the suggestions, set the address and validation and return.
        if (value === getSingleLineAddress(address)) {
            addressType === 'reportAddress' ? setReportAddressSelected(true) : setInvoiceAddressSelected(true);
            setSingleLineAddresses(addressType, value);

            tempValidator[addressType][name].error = false;
            setValidator(tempValidator);
            return;
        }

        if (value.length >= 3) dispatch(new AddressAction().getAddressSuggestions(value)).then((response) => {
            if (response?.data) {
                const { suggestions } = response.data;
                if (addressType === 'reportAddress') setReportAddressSuggestions(suggestions);
                if (addressType === 'invoiceAddress') setInvoiceAddressSuggestions(suggestions);
            }
        });

        tempValidator[addressType][name].error = !validateText(name, value);
        setValidator(tempValidator);

        addressType === 'reportAddress' ? setReportAddressSelected(false) : setInvoiceAddressSelected(false);
        setSingleLineAddresses(addressType, value);
    }

    // Handler when an Address suggestion from the provided list of Autocomplete Addresses is clicked.
    function handleAddressSelection(selectedAddress, addressType) {
        setIsAccountValid(true);
        setSingleLineAddresses(addressType, selectedAddress.suggestion);
        addressType === 'reportAddress' ? setReportAddressSelected(true) : setInvoiceAddressSelected(true);
        dispatch(new AddressAction().getAddressDetails(selectedAddress.propertyId)).then((response) => {
            if (response?.data) {
                const addressDetails = response?.data;
                const tempAddress = addressType === 'invoiceAddress' ? {...invoiceAddress} : {...reportAddress};
                const { street, locality, state, postcode } = addressDetails;
                tempAddress.addressLine = street.nameAndNumber;
                tempAddress.city = locality.name;
                tempAddress.state = state;
                tempAddress.postalCode = postcode.name;
                if (addressType === 'reportAddress') setReportAddress(tempAddress);
                if (addressType === 'invoiceAddress') setInvoiceAddress(tempAddress);

                const addressBeforeChange = getAddressFromReducer(addressType, customer);
                if (street.nameAndNumber !== addressBeforeChange?.addressLine ||
                    locality.name !== addressBeforeChange?.city ||
                    state !== addressBeforeChange?.state ||
                    postcode !== addressBeforeChange.postalCode) {

                    const tempUpdatedFields = {...updatedFields};
                    const addressChanges = {
                        addressLine: street.nameAndNumber,
                        city: locality.name,
                        state: state,
                        postalCode: postcode.name,
                    };
                    tempUpdatedFields[addressType] = addressChanges;
                    setUpdatedFields(tempUpdatedFields);
                }
            }
        });
    }

    const getConfirmChangesModalFooter = () => {
        return (
            <Box pr={2}>
                <Button size="small" variant="contained" color="primary" onClick={handleConfirmChanges}>
                    CONFIRM
                </Button>
                <Button size="small" onClick={toggleConfirmChangesModal} style={{margin: '0px 10px'}}>
                    GO BACK
                </Button>
            </Box>
        );
    };


    function cancelManualAddressMode(addressType) {
        !new SegmentAnalyticsService().trackAccountAndContact('Cancel enter address manually', SECTION);

        const tempValidator = {...validator};
        const addressValidator = tempValidator[addressType];
        addressValidator.singleLineAddress.error = false;
        addressValidator.addressLine.error = false;
        addressValidator.city.error = false;
        addressValidator.state.error = false;
        addressValidator.postalCode.error = false;

        addressValidator.singleLineAddress.required = true;
        addressValidator.addressLine.required = true;
        addressValidator.city.required = true;
        addressValidator.state.required = true;
        addressValidator.postalCode.required = true;

        const address = getAddressFromReducer(addressType, customer);
        const singeLineAddress = getSingleLineAddress(address);
        setSingleLineAddresses(addressType, singeLineAddress);

        setValidator(tempValidator);
    }

    function setManualAddressEntryValidator(addressType) {
        !new SegmentAnalyticsService().trackAccountAndContact('Enter address manually', SECTION);

        const tempValidator = {...validator};
        tempValidator[addressType].singleLineAddress.error = false;
        tempValidator[addressType].addressLine.error = false;
        tempValidator[addressType].city.error = false;
        tempValidator[addressType].state.error = false;
        tempValidator[addressType].postalCode.error = false;


        tempValidator[addressType].singleLineAddress.required = false;
        tempValidator[addressType].addressLine.required = true;
        tempValidator[addressType].city.required = true;
        tempValidator[addressType].state.required = true;
        tempValidator[addressType].postalCode.required = true;

        setValidator(tempValidator);
    }

    function validateAccount() {
        const tempValidator = {...validator};
        let isValid = true;
        for (const [key, value] of Object.entries(tempValidator))
            if (value.required || (account[key] && account[key].length > 0))
                value.error = !validateText(key, account[key]);

        setValidator(tempValidator);

        isValid = hasNoValidationErrors(tempValidator);
        setIsAccountValid(isValid);
        return isValid;
    }

    const validateAddresses = () => {
        const tempValidator = {...validator};

        // Check to see if single line address was selected from dropdown
        if (reportAddress)
            tempValidator.reportAddress.singleLineAddress.error = tempValidator.reportAddress.singleLineAddress.required && !reportAddressSelected;
        if (invoiceAddress)
            tempValidator.invoiceAddress.singleLineAddress.error = tempValidator.invoiceAddress.singleLineAddress.required && !invoiceAddressSelected;


        if (!invoiceAddressSameAsReport && !singleLineInvoiceAddress) {
            tempValidator.invoiceAddress.singleLineAddress.error = true;
            tempValidator.invoiceAddress.singleLineAddress.required = true;
            setIsAccountValid(false);
        }

        if (invoiceAddressSameAsReport && !invoiceAddress) {
            tempValidator.invoiceAddress.singleLineAddress.error = false;
            tempValidator.invoiceAddress.singleLineAddress.required = false;
            validateAccount();
        }

        setValidator(tempValidator);
        return !(tempValidator.reportAddress.singleLineAddress.error || tempValidator.invoiceAddress.singleLineAddress.error);
    };

    const validatePhoneNumber = () => {
        const tempValidator = {...validator};
        const isPhoneNumberValid = isValidPhoneNumber(account.phoneAreaCode, account.phoneNumber);
        if (isPhoneNumberValid && tempValidator.phoneNumber.error) {
            tempValidator.phoneNumber.error = false;
            const isValid = hasNoValidationErrors(tempValidator);
            setIsAccountValid(isValid);
            setValidator(tempValidator);
        } else if (!isPhoneNumberValid && !tempValidator.phoneNumber.error) {
            tempValidator.phoneNumber.error = true;
            setIsAccountValid(false);
            setValidator(tempValidator);
        }
    };

    const processAddressChanges = (addressesBeforeChanges) => {
        const validatedReportAddress = validateReportAddress({...reportAddress}, invoiceAddressSameAsReport);
        const validatedInvoiceAddress = validateInvoiceAddress({...invoiceAddress}, invoiceAddressSameAsReport);
        const addressesAfterChanges = [validatedReportAddress, validatedInvoiceAddress];
        const addressesToBeCreated = getAddressesToBeCreated(addressesAfterChanges);
        const addressesToBeDeleted = getAddressesToBeDeleted(addressesBeforeChanges, addressesAfterChanges);
        const addressesToBeUpdated = getAddressesToBeUpdated(validatedReportAddress, validatedInvoiceAddress, updatedFields);

        setAddressActionsInProgress(true);

        updateAddress(addressesToBeUpdated).then(() => {
            createAddress(addressesToBeCreated).then(() => {
                deleteAddress(addressesToBeDeleted).then(() => {
                    setAddressActionsInProgress(false);
                    setUpdatesProcessed(true);
                });
            });
        });
    };

    const updateAddress = (addressesToBeUpdated) => {
        return new Promise((resolve, reject) => {
            if (addressesToBeUpdated.length === 0) return resolve();
            dispatch(new AddressAction().updateAddresses({
                query: UPDATE_ADDRESS.loc.source.body,
                variables: {
                    input: {
                        addresses: addressesToBeUpdated,
                    },
                },
            })).then(
                () => resolve(),
                () => reject(),
            );
        });

    };

    const createAddress = (addressesToBeCreated) => {
        return new Promise((resolve, reject) => {
            if (addressesToBeCreated.length === 0) return resolve();
            dispatch(new AddressAction().createAddresses({
                query: CREATE_ADDRESS.loc.source.body,
                variables: {
                    input: {
                        addresses: addressesToBeCreated,
                    },
                },
            })).then(
                () => resolve(),
                () => reject(),
            );
        });
    };

    const deleteAddress = (addressesToBeDeleted) => {
        return new Promise((resolve, reject) => {
            if (addressesToBeDeleted.length === 0) return resolve();
            dispatch(new AddressAction().deleteAddresses({
                variables: {
                    input: {
                        addresses: addressesToBeDeleted,
                    },
                },
            })).then(
                () => resolve(),
                () => reject(),
            );
        });
    };

    const updateAccount = () => {
        return new Promise((resolve, reject) => {
            dispatch(new CustomerAction().updateAccount({
                query: UPDATE_ACCOUNT.loc.source.body,
                variables: {
                    input: {
                        account: {
                            organizationDEOXAccountEmailC: account.organizationDEOXAccountEmailC,
                            phoneAreaCode: account.phoneAreaCode,
                            phoneCountryCode: account.phoneCountryCode,
                            phoneNumber: account.phoneNumber.replace(/\s/g, ''),
                        },
                    },
                },
            })).then(
                () => resolve(),
                () => reject(),
            );
        });
    };

    const updateContact = (contact) => {
        return new Promise((resolve, reject) => {
            dispatch(new CustomerAction().updateContact({
                query: UPDATE_CONTACT.loc.source.body, 
                variables: {
                    input: {
                        partyNumber: contact.partyNumber,
                        contact: {
                            emailAddress: contact.emailAddress,
                        },
                    },
                },
            })).then(
                () => resolve(),
                () => reject(),
            );
        });
    };

    const updateContacts = async () => {
        const contactsToBeUpdated = getContactsToBeUpdated(updatedFields, account);
        if (contactsToBeUpdated.length > 0) 
            return await Promise.all(contactsToBeUpdated.map((contact) => updateContact(contact)));
        else 
            return Promise.resolve();
    };


    function update(){
        const addressesBeforeChanges = [...customer.customer?.account?.addresses];
        if (hasOnlyAddressChanges(updatedFields)) {
            processAddressChanges(addressesBeforeChanges);
            return;
        }

        updateAccount().then(() => {
            updateContacts().then(() => {
                if (hasAddressChanges(updatedFields))
                    processAddressChanges(addressesBeforeChanges);
                else
                    setUpdatesProcessed(true);
            });
        });
    }

    const afterSuccessfulActions = () => {
        !new SegmentAnalyticsService().trackAccountAndContact('Save Changes', SECTION, 'Successful');

        setShowAlert(true);
        setIsAccountValid(false);
        setUpdatedFields({});
        setUpdatesProcessed(false);
        props.getAccountInfo();
    };

    const afterUnsuccessfulActions = () => {
        !new SegmentAnalyticsService().trackAccountAndContact('Save Changes', SECTION, 'Failed');
        setShowAlert(true);
        setUpdatesProcessed(false);
    };

    const updatesForReportAddressWhenInvoiceAddressSameAsReport = (tempUpdatedFields) => {
        // Make changes to updatedFields related to the ReportAddress
        if (tempUpdatedFields.reportAddress) tempUpdatedFields.reportAddress['addressType'] = 'BILL_TO';
        if (!tempUpdatedFields.reportAddress)
            tempUpdatedFields.reportAddress = {
                addressType: 'BILL_TO',
            };
        // If the pre-updated state of Report Address was already the Invoice address then remove that change from updatedFields
        const reportAddressPreUpdate = getAddressFromReducer('reportAddress', customer);
        if (
            isReportAndInvoiceAddress(reportAddress, customer.customer.account.addresses) &&
            tempUpdatedFields.reportAddress?.addressType
        ) {
            if (reportAddressPreUpdate?.addressType === tempUpdatedFields.reportAddress?.addressType)
                delete tempUpdatedFields.reportAddress?.addressType;
            if (objectIsEmpty(tempUpdatedFields.reportAddress)) delete tempUpdatedFields.reportAddress;
        }

        // Update the report Address State
        const tempReportAddress = {...reportAddress};
        if (tempReportAddress?.addressType === null || tempReportAddress?.addressType?.length === 0)
            tempReportAddress.addressType = 'BILL_TO';
        else if (!tempReportAddress?.addressType?.includes('BILL_TO'))
            tempReportAddress.addressType += ',BILL_TO';

        setReportAddress(tempReportAddress);

        return tempUpdatedFields;
    };

    const updatesForInvoiceAddressWhenInvoiceAddressSameAsReport = (tempUpdatedFields) => {
        // Make changes to updatedFields related to the InvoiceAddress
        if (tempUpdatedFields.invoiceAddress?.addressType) delete tempUpdatedFields.invoiceAddress?.addressType;

        // If the Invoice address was never originally present before changes then remove it from the updatedFields
        if (
            !getAddressFromReducer('invoiceAddress', customer) &&
            'invoiceAddress' in tempUpdatedFields
        )
            delete tempUpdatedFields['invoiceAddress'];
        else {
            tempUpdatedFields['invoiceAddress'] = invoiceAddress || {};
            tempUpdatedFields.invoiceAddress['deleted'] = true;
        }

        // Update the Invoice Addess state to empty/null
        setSingleLineInvoiceAddress('');
        setInvoiceAddress(null);

        return tempUpdatedFields;
    };

    const updatesForReportAddressWhenInvoiceAddressIsNotSameAsReport = (tempUpdatedFields) => {
        if (tempUpdatedFields.reportAddress?.addressType)
            tempUpdatedFields.reportAddress.addressType = null;
        else if (!tempUpdatedFields.reportAddress)
            tempUpdatedFields['reportAddress'] = {
                addressType: null,
            };

        return tempUpdatedFields;
    };

    const updatesForInvoiceAddressWhenInvoiceAddressIsNotSameAsReport = (tempUpdatedFields) => {
        if (tempUpdatedFields.invoiceAddress) tempUpdatedFields.invoiceAddress['addressType'] = 'BILL_TO';
        if (!tempUpdatedFields.invoiceAddress)
            tempUpdatedFields.invoiceAddress = {
                addressType: 'BILL_TO',
            };

        return tempUpdatedFields;
    };

    const toggleInvoiceAddress = () => {
        const isInvoiceAddressSameAsReport = !invoiceAddressSameAsReport;
        let tempUpdatedFields = {...updatedFields};

        // When `Organisation Address To Appear On Invoices` is ticked
        if (isInvoiceAddressSameAsReport) {
            tempUpdatedFields = updatesForReportAddressWhenInvoiceAddressSameAsReport(tempUpdatedFields);
            tempUpdatedFields = updatesForInvoiceAddressWhenInvoiceAddressSameAsReport(tempUpdatedFields);
        // When `Organisation Address To Appear On Invoices` is un-ticked
        } else {
            tempUpdatedFields = updatesForReportAddressWhenInvoiceAddressIsNotSameAsReport(tempUpdatedFields);
            tempUpdatedFields = updatesForInvoiceAddressWhenInvoiceAddressIsNotSameAsReport(tempUpdatedFields);
            validateAddresses();
        }

        setUpdatedFields(tempUpdatedFields);
        setInvoiceAddressSameAsReport(!invoiceAddressSameAsReport);
    };

    const computeLogicForInvoiceAddressSameAsReport = () => {
        const addresses = account.addresses;
        if (addresses?.length > 0)
            for (const address of addresses)
                if (isReportAndInvoiceAddress(address, addresses)) setInvoiceAddressSameAsReport(true);
        if (invoiceAddress)
            setInvoiceAddressSameAsReport(false);
    };

    const updateWasSuccessful = () => 
        addressReducer.addressUpdateSuccess &&
        customer.accountUpdateSuccess &&
        customer.contactUpdateSuccess;

    function resetAccount(){
        !new SegmentAnalyticsService().trackAccountAndContact('Reset', SECTION);

        setAccount(customer.customer.account);
        setUpdatedFields({});
        setValidator({...VALIDATOR});
        setReportAddressSelected(true);
        setInvoiceAddressSelected(true);
        setInvoiceAddressSameAsReport(false);

        let addressType = '';
        let address = '';
        let singleLineAddress = '';

        // Set report address default state
        addressType = 'reportAddress';
        address = getAddressFromReducer(addressType, customer);
        singleLineAddress = getSingleLineAddress(address);
        setReportAddress(address);
        setSingleLineReportAddress(singleLineAddress);

        // Set invoice address default state
        addressType = 'invoiceAddress';
        address = getAddressFromReducer(addressType, customer);
        singleLineAddress = getSingleLineAddress(address);
        setInvoiceAddress(address);
        setSingleLineInvoiceAddress(singleLineAddress);

        computeLogicForInvoiceAddressSameAsReport();
        
        setResetComponent(true);
    }

    useEffect(() => {
        const addresses = account.addresses;
        if (addresses?.length > 0)
            for (const address of addresses) {
                if (isReportAddress(address) && !reportAddress) setReportAddress(address);
                else if (isInvoiceAddress(address) && !invoiceAddress && !invoiceAddressSameAsReport) setInvoiceAddress(address);
                computeLogicForInvoiceAddressSameAsReport();
            }
        validateAccount();
        validateAddresses();
    }, [account]);

    useEffect(() => {
        validateAddresses();
    }, [singleLineInvoiceAddress]);

    useEffect(() => {
        if (reportAddress) setSingleLineReportAddress(getSingleLineAddress(reportAddress));
        if (invoiceAddress) setSingleLineInvoiceAddress(getSingleLineAddress(invoiceAddress));

        computeLogicForInvoiceAddressSameAsReport();
    }, [reportAddress, invoiceAddress]);

    useEffect(() => {
        if (resetComponent) setResetComponent(false);
    }, [resetComponent]);

    useEffect(() => {
        setPermissions(getAccountDetailsPermissions());
    }, [customer?.setPermissions]);

    useEffect(() => {
        setAccount({...customer.customer?.account});
    }, [customer.customer?.account]);

    useEffect(() => {
        if (!updatesProcessed) return;
        if (updateWasSuccessful())
            afterSuccessfulActions();
        else
            afterUnsuccessfulActions();
    }, [updatesProcessed]);

    validatePhoneNumber();

    return (
        <Card className={classes.card}>
            <CardHeader
                className={classes.cardHeader}
                title={
                    <span className="card-header-title">
                        <DomainIcon style={{ margin: '-4px 5px -4px 0px', color: '#38424A' }}/>
                        Manage your organisation's account details
                    </span>
                }
                subheader={
                    <span className="card-subheader-title">
                        <span className={classes.asterisk}>*</span> indicates required field
                    </span>
                }
            />
            <CardContent className={classes.cardContent}>
                <form noValidate autoComplete="nope">
                    {/* Trick to disable browser auto complete and auto suggesstions as autoComplete='off' won't work */}
                    <input autoComplete="false" name="hidden" type="text" className="hidden" />
                    <Grid container className={classes.grid} spacing={5}>
                        {/* Account Name */}
                        <Grid item sm={6} xs={12}>
                            { loading && showLabelSkelton() }
                            { loading && readOnlyTextSkelton() }
                            { !loading && (
                                <React.Fragment>
                                    <FormControl>
                                        <InputLabel id="organisation-account-label" shrink className="input-field-label">
                                            Corelogic Account Name
                                        </InputLabel>
                                    </FormControl>
                                    <Typography variant="body1" gutterBottom className="text-left typography">
                                        { account?.organizationDEOXTradingNameC }
                                        <Box mb={1} />
                                        <a className="info-link" onClick={() => showFieldInfoModal(FIELD_INFO_TYPES.ACCOUNT_NAME)}>
                                            Want to change your Account Name?
                                        </a>
                                    </Typography>
                                </React.Fragment>
                            )}
                        </Grid>
                        {/* Account Number */}
                        <Grid item sm={6} xs={12}>
                            { loading && showLabelSkelton() }
                            { loading && readOnlyTextSkelton() }
                            { !loading && (
                                <React.Fragment>
                                    <FormControl>
                                        <InputLabel id="organisation-email-label" htmlFor="organisation-email" shrink className="input-field-label">
                                            Corelogic Account Number
                                        </InputLabel>
                                    </FormControl>
                                    <Typography variant="body1" gutterBottom className="text-left typography">
                                        { user.profile ? user.profile.accountNumber : '' }
                                    </Typography>
                                </React.Fragment>
                            )}
                        </Grid>
                        {/* Business Name */}
                        <Grid item sm={6} xs={12}>
                            { loading && showLabelSkelton() }
                            { loading && readOnlyTextSkelton() }
                            { !loading && (
                                <React.Fragment>
                                    <FormControl>
                                        <InputLabel id="organisation-business-name-label" shrink className="input-field-label">
                                            Business Name
                                        </InputLabel>
                                    </FormControl>
                                    <Typography variant="body1" gutterBottom className="text-left typography">
                                        { !loading && account?.organizationDEOXTradingNameC }
                                        <Box mb={1} />
                                        <a className="info-link" onClick={() => showFieldInfoModal(FIELD_INFO_TYPES.BUSINESS_NAME)}>
                                            Want to change your Business Name?
                                        </a>
                                    </Typography>
                                </React.Fragment>    
                            )}
                        </Grid>
                        {/* ABN */}
                        <Grid item sm={6} xs={12}>
                            { loading && showLabelSkelton() }
                            { loading && readOnlyTextSkelton() }
                            { !loading && (
                                <React.Fragment>
                                    <FormControl>
                                        <InputLabel id="organisation-abn-acn-label" shrink className="input-field-label">
                                            {businessNumber.length === 9 ? 'ACN' : 'ABN'}
                                        </InputLabel>
                                    </FormControl>
                                    <Typography variant="body1" gutterBottom className="text-left typography">
                                        { formatBusinessNumber(businessNumber) }
                                        <Box mb={1} />
                                        <a className="info-link" onClick={() => showFieldInfoModal(FIELD_INFO_TYPES.ABN)}>
                                            Want to change your ABN?
                                        </a>
                                    </Typography>
                                </React.Fragment>
                            )}
                        </Grid>
                        {/* Organisation Email Address */}
                        <Grid item sm={6} xs={12}>
                            { loading && showLabelSkelton() }
                            { loading && textFieldSkeleton() }
                            { !loading && (
                                <React.Fragment>
                                    <FormControl>
                                        <InputLabel id="organisation-email-label" htmlFor="organisation-email" shrink className="input-field-label">
                                        Organisation Email <span className={classes.asterisk}>*</span>
                                            <Tooltip title="This is your organisation's Primary email address." leaveTouchDelay={3000} enterTouchDelay={50}>
                                                <ErrorIcon className="custom-tooltip"/>
                                            </Tooltip>
                                        
                                        </InputLabel>
                                        { permissions?.organisation_email && (
                                            <BootstrapInput
                                                id="organisation-email" value={account.organizationDEOXAccountEmailC}
                                                onChange={handleChange} name="organizationDEOXAccountEmailC" error={validator.organizationDEOXAccountEmailC.error}
                                                autoComplete="nope"
                                            />
                                        )}
                                    </FormControl>
                                    { !permissions?.organisation_email && (
                                        <Typography variant="body1" gutterBottom className="text-left typography">
                                            { account.organizationDEOXAccountEmailC }
                                        </Typography>
                                    )}
                                </React.Fragment>
                            )}
                            {
                                !loading && validator.organizationDEOXAccountEmailC.error &&
                                <FormHelperText error>
                                    <span style={{'display': 'inline-block'}}>
                                        Email should contain an @ and a correct domain suffix.
                                    </span>
                                </FormHelperText>
                            }
                        </Grid>
                        {/* Organisation Phone */}
                        <Grid item sm={6} xs={12} className={classes.phoneNumberAndAreaCode}>
                            { loading && showLabelSkelton() }
                            { loading && textFieldSkeleton() }
                            { !loading && (
                                <FormControl className={classes.organisationPhoneLabel}>
                                    <InputLabel
                                        error={validator.phoneAreaCode.error || validator.phoneNumber.error}
                                        id="organisation-phone-label" shrink className="input-field-label">
                                        Organisation Phone <span className={classes.asterisk}>*</span>
                                        <Tooltip title="This is your organisation's best contact number." leaveTouchDelay={3000} enterTouchDelay={50}>
                                            <ErrorIcon className="custom-tooltip"/>
                                        </Tooltip>
                                    </InputLabel>
                                </FormControl>
                            )}

                            { !loading && (
                                <React.Fragment>
                                    { !permissions?.organisation_phone && (
                                        <Typography variant="body1" gutterBottom className="text-left typography">
                                            {account.phoneCountryCode ? '+' + account.phoneCountryCode : ''} { account.phoneAreaCode }{' '}{ account.phoneNumber }
                                        </Typography>
                                    )}
                                    { permissions?.organisation_phone && (
                                        <React.Fragment>
                                            <FormControl style={{'width': '40px'}}>
                                                <div className="text-left country-code-label">
                                                    {account.phoneCountryCode ? '+' + account.phoneCountryCode : ''}
                                                </div>
                                            </FormControl>
                                            <FormControl style={{'width': '80px'}}>
                                                <BootstrapInput
                                                    id="organisation-phone-area-code" value={account.phoneAreaCode} classes={{input: classes.areaCode}}
                                                    error={validator.phoneAreaCode.error} onChange={handleChange} name="phoneAreaCode" autoComplete="nope" />
                                            </FormControl>
                                            <FormControl style={{'width': '180px'}}>
                                                <BootstrapInput
                                                    id="organisation-phone-number" value={account.phoneNumber} classes={{input: classes.phoneNumber}}
                                                    error={validator.phoneNumber.error} onChange={handleChange} name="phoneNumber" autoComplete="nope" />
                                            </FormControl>
                                        </React.Fragment>
                                    )}
                                    { (validator.phoneAreaCode.error || validator.phoneNumber.error) &&
                                        <FormHelperText error>
                                            {
                                                validator.phoneAreaCode.error &&
                                                <span style={{'display': 'inline-block'}}>Area code must contain numbers only eg 7 or 13</span>
                                            }
                                            {
                                                validator.phoneNumber.error &&
                                                <span style={{'display': 'inline-block'}}>
                                                    Phone numbers are to be '04xx xxx xxx' or '(area code) xxxx xxxx'.
                                                </span>
                                            }
                                        </FormHelperText>
                                    }
                                </React.Fragment>
                            )}
                        </Grid>

                        {/* Report Address */}
                        { loading && (
                            <Grid item sm={12} xs={12}>
                                { loading && showLabelSkelton() }
                                { loading && addressFieldSkeleton() }
                            </Grid>
                        )}

                        { !loading && (
                            <AddressInput
                                addressType={'reportAddress'}
                                validator={validator}
                                address={reportAddress}
                                addressSuggestions={reportAddressSuggestions}
                                singleLineAddress={singleLineReportAddress}
                                handleManualAddressChange={handleManualAddressChange}
                                handleAutocompleteAddressChange={handleAutocompleteAddressChange}
                                handleAddressSelection={handleAddressSelection}
                                setManualAddressEntryValidator={setManualAddressEntryValidator}
                                cancelManualAddressMode={cancelManualAddressMode}
                                resetComponent={resetComponent}
                                readOnly={!permissions?.report_address}
                            />    
                        )}

                        { permissions?.invoice_checkbox && !loading && (
                            <Grid item sm={12} xs={12} className={classes.invoiceAddressCheckbox}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={invoiceAddressSameAsReport} onChange={toggleInvoiceAddress} style={{ pointerEvents: 'auto' }}/>
                                        }
                                        label={
                                            <div>
                                                Organisation Address to appear on invoices
                                                {invoiceAddressSameAsReport &&
                                                    <Tooltip title="This address shows up in the address section of the invoices we email to you." leaveTouchDelay={3000} enterTouchDelay={50} onClick={(e) => e.preventDefault()} style={{ pointerEvents: 'auto' }}>
                                                        <ErrorIcon className={`custom-tooltip ${classes.customTooltip}`} />
                                                    </Tooltip>
                                                }
                                            </div>
                                        }
                                        className={classes.invoiceAddressCheckbox}
                                    />
                                </FormGroup>
                            </Grid>
                        )}

                        {/* Invoice Address */}
                        {!loading && !invoiceAddressSameAsReport && (
                            <AddressInput
                                addressType={'invoiceAddress'}
                                validator={validator}
                                address={invoiceAddress}
                                addressSuggestions={invoiceAddressSuggestions}
                                singleLineAddress={singleLineInvoiceAddress}
                                handleManualAddressChange={handleManualAddressChange}
                                handleAutocompleteAddressChange={handleAutocompleteAddressChange}
                                handleAddressSelection={handleAddressSelection}
                                setManualAddressEntryValidator={setManualAddressEntryValidator}
                                cancelManualAddressMode={cancelManualAddressMode}
                                resetComponent={resetComponent}
                                readOnly={!permissions.invoice_address}
                            />
                        )}

                        {/* Primary Contact */}
                        <Grid item sm={6} xs={12}>
                            { loading && showLabelSkelton() }
                            { loading && readOnlyTextSkelton() }
                            { !loading && (
                                <React.Fragment>
                                    <FormControl>
                                        <InputLabel id="organisation-primary-contact-label" shrink className={`input-field-label ${classes.label}`}>
                                            {primaryAndPaymentContactIsSame(account) ? 'Primary & Payments Contact' : 'Primary Contact'}
                                            <Tooltip title={getPrimaryContactTooltip(account)} leaveTouchDelay={3000} enterTouchDelay={50}>
                                                <ErrorIcon className="custom-tooltip"/>
                                            </Tooltip>
                                        </InputLabel>
                                    </FormControl>
                                    <Typography variant="body1" gutterBottom className="text-left typography">
                                        { customer.customer?.contact?.isPrimaryContact && <span>Me&nbsp;</span>}
                                        { customer.customer?.contact?.isPrimaryContact && <span>(</span>}
                                        { account.primaryContactName}
                                        { customer.customer?.contact?.isPrimaryContact && <span>)</span>}
                                        <Box mb={1} />
                                        <a className="info-link" onClick={() => showFieldInfoModal(FIELD_INFO_TYPES.PRIMARY_CONTACT)}>
                                            Want to change the Primary Contact?
                                        </a>
                                    </Typography>
                                </React.Fragment>
                            )}
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            { loading && showLabelSkelton() }
                            { loading && textFieldSkeleton() }
                            { !loading && (
                                <React.Fragment>
                                    <FormControl>
                                        <InputLabel id="organisation-primary-contact-email" shrink className={`input-field-label ${classes.label}`}>
                                            {primaryAndPaymentContactIsSame(account) ? 'Primary & Payments Contact Email' : 'Primary Contact Email'}
                                            <span className={classes.asterisk}>*</span>
                                            <Tooltip title={getPrimaryContactTooltip(account)} leaveTouchDelay={3000} enterTouchDelay={50}>
                                                <ErrorIcon className="custom-tooltip"/>
                                            </Tooltip>
                                        </InputLabel>
                                        { permissions.primary_contact_email_address && (
                                            <BootstrapInput
                                                id="organisation-primary-contact-email" value={account.primaryContactEmailAddress}
                                                error={validator.primaryContactEmailAddress.error} onChange={handleChange} name="primaryContactEmailAddress" autoComplete="nope" />
                                        )}
                                        
                                    </FormControl>
                                    { !permissions.primary_contact_email_address && (
                                        <Typography variant="body1" gutterBottom className="text-left typography">
                                            { account.primaryContactEmailAddress }
                                        </Typography>
                                    )}
                                </React.Fragment>
                            )}
                            {
                                !loading && validator.primaryContactEmailAddress.error &&
                                <FormHelperText error>
                                    <span style={{'display': 'inline-block'}}>
                                        Email should contain an @ and a correct domain suffix.
                                    </span>
                                </FormHelperText>
                            }
                        </Grid>

                        {/* Payments Contact */}
                        { !primaryAndPaymentContactIsSame(account) && (
                            <React.Fragment>
                                <Grid item sm={6} xs={12}>
                                    { loading && showLabelSkelton() }
                                    { loading && readOnlyTextSkelton() }
                                    { !loading && (
                                        <React.Fragment>
                                            <FormControl>
                                                <InputLabel id="organisation-primary-payment-label" shrink className="input-field-label">
                                                    Payments Contact
                                                    <Tooltip title="This contact will be used for billing and financial related correspondence.  For example invoices will be sent to this contact." leaveTouchDelay={3000} enterTouchDelay={50}>
                                                        <ErrorIcon className="custom-tooltip"/>
                                                    </Tooltip>
                                                </InputLabel>
                                            </FormControl>
                                            <Typography variant="body1" gutterBottom className="text-left typography">
                                                { customer.customer?.contact?.isPrimaryPaymentContact && <span>Me&nbsp;</span>}
                                                { customer.customer?.contact?.isPrimaryPaymentContact && <span>(</span>}
                                                { account.organizationDEOXNewContactForPaymentC}
                                                { customer.customer?.contact?.isPrimaryPaymentContact && <span>)</span>}
                                                <Box mb={1} />
                                                <a className="info-link" onClick={() => showFieldInfoModal(FIELD_INFO_TYPES.PAYMENT_CONTACT)}>
                                                    Want to change the Payments Contact?
                                                </a>
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    { loading && showLabelSkelton() }
                                    { loading && textFieldSkeleton() }
                                    { !loading && (
                                        <React.Fragment>
                                            <FormControl>
                                                <InputLabel id="organisation-payments-contact-email" className={`${classes.label} input-field-label`}>
                                                    Payments Contact Email <span className={classes.asterisk}>*</span>
                                                    <Tooltip title="This contact will be used for billing and financial related correspondence.  For example invoices will be sent to this contact." leaveTouchDelay={3000} enterTouchDelay={50}>
                                                        <ErrorIcon className="custom-tooltip"/>
                                                    </Tooltip>
                                                </InputLabel>
                                                { permissions.payment_contact_email_address && (
                                                    <BootstrapInput
                                                        id="organisation-payments-contact-email" value={account.paymentContactEmailAddress}
                                                        error={validator.paymentContactEmailAddress.error} onChange={handleChange} name="paymentContactEmailAddress" autoComplete="nope" />
                                                )}
                                            </FormControl>
                                            { !permissions.payment_contact_email_address && (
                                                <Typography variant="body1" gutterBottom className="text-left typography">
                                                    { account.paymentContactEmailAddress }
                                                </Typography>
                                            )}
                                        </React.Fragment>
                                    )}
                                    {
                                        !loading && validator.paymentContactEmailAddress.error &&
                                        <FormHelperText error>
                                            <span style={{'display': 'inline-block'}}>
                                                Email should contain an @ and a correct domain suffix.
                                            </span>
                                        </FormHelperText>
                                    }
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </form>
                <ModalComponent
                    modalHeader={getConfirmChangesModalTitle()}
                    modalBody={getConfirmChangesModalBody(updatedFields, customer.customer?.account, reportAddress, invoiceAddress)}
                    modalFooter={getConfirmChangesModalFooter()}
                    isOpen={confirmChangesModal}
                    toggleModal={toggleConfirmChangesModal}
                />
                <ModalComponent
                    modalHeader={getFieldsInfoTitle(fieldsInfoType)}
                    modalBody={getFieldsInfoBody(fieldsInfoType)}
                    isOpen={fieldsInfoModal}
                    toggleModal={toggleFieldsInfoModal}
                />
            </CardContent>
            { permissions.save_changes && !loading && (
                <CardActions disableSpacing style={{padding: '25px 20px 20px'}}>
                    <React.Fragment>
                        <Button variant="contained" color="primary" onClick={onSaveChangesClick}
                            disabled={
                                !isAccountValid ||
                                customer.accountLoader ||
                                addressActionsInProgress ||
                                objectIsEmpty(updatedFields)
                            }>
                            Save Changes
                            {
                                (customer.accountLoader || addressActionsInProgress) &&
                                <React.Fragment>
                                    <CircularProgress size={20} className={classes.spinner} />
                                </React.Fragment>

                            }
                        </Button> &nbsp;
                        <Button color="primary" onClick={resetAccount} >
                            Reset Changes
                        </Button>
                    </React.Fragment>
                </CardActions>
            )}
            <Snackbar className="alert-toast" open={showAlert && updateWasSuccessful()} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="success">
                    Your details have been updated successfully
                </Alert>
            </Snackbar>
            <Snackbar className="alert-toast" open={showAlert && !updateWasSuccessful()} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="error">
                Sorry, your details were not updated. Please try again or contact us via Chat or contact our Customer Care team on 1300 734 318
                </Alert>
            </Snackbar>
        </Card>
    );
}
