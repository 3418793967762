import React from 'react';
import { SegmentAnalyticsService } from '../controller/services';
import Wrench from '../image/wrench.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    wrenchIcon: {
        width: '150px',
        height: '125px',
        [theme.breakpoints.down('xs')]: {
            margin: '.5rem !important',
            width: '150px',
            height: '125px',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '1rem',
            width: '150px',
            height: '125px',
        },
        margin: '2rem',
    },
}));

export default function SelfServiceNAPage(){
    const classes = useStyles();

    const handleLinkClick = () => {
        !new SegmentAnalyticsService().trackField('Click Customer Care Support', null);
    };

    return (
        <div className="body-wrapper">
            <div className="body-wrapper-child-lg na-page-text">
                <h2 style={{fontWeight: '400'}}>
                    The customer administration portal is currently undergoing an exciting upgrade.<br/>
                    While we are expanding the current services the portal will not be available</h2>
                <img
                    alt="Line"
                    className={classes.wrenchIcon}
                    src={Wrench}
                />
                <div style={{fontSize: '20px'}}>
                    In the meantime, please get in contact with our friendly support staff to assist with your request by clicking 
                    <a href="https://corelogic.com.au/contact-us" onClick={handleLinkClick}> here</a>.<br/>
                    We apologise for any inconvenience during this outage and thank you in advance for your patience and understanding.
                </div>
            </div>
        </div>
    );
}
