import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    CardHeader,
    Card,
    CardContent,
    Typography,
    Box,
    Grid,
    Snackbar,
} from '@material-ui/core';
import CreditCard from '@material-ui/icons/CreditCard';
import {useDispatch, useSelector} from 'react-redux';
import {CustomerAction} from '../../../../controller/actions';
import Alert from '../../general/alert';
import { 
    hasNoDefaultPaymentMethod,
    hasDirectDebitPaymentMethod,
    hasCreditCardPaymentMethod,
} from '../../../../controller/services/paymentMethodService';
import { SegmentAnalyticsService } from '../../../../controller/services';
import LoadingSkeleton from './paymentDetails/loadingSkeleton';
import NoDefaultPaymentMethod from './paymentDetails/noDefaultPaymentMethod';
import DirectDebitPaymentMethod from './paymentDetails/directDebitPaymentMethod';
import CreditCardPaymentMethod from './paymentDetails/creditCardPaymentMethod';
import { objectIsEmpty } from '../../../../controller/services/helper';

const useStyles = makeStyles(() => ({
    grid: {
        margin: '-20px 0',
    },
    card: {
        textAlign: 'left',
    },
    cardHeader: {
        backgroundColor: '#eee',
    },
    disableTextTranform: {
        textTransform: 'none !important',
    },
    note: {
        fontSize: '14px',
        padding: '0 6px',
        paddingTop: '12px',
    },
    anchor: {
        fontSize: '12px',
    },
}));

/**
 * Payment Details Component.
 * Displays the payment details. Also, allows the user to update the details.
 *
 * @param       {Object}        paymentDetails              The object which contains the Payment details. This object will be used to display the information in the card and form.
 * @param       {Function}      onPaymentDetailsChange      Function that listens to the form input changes and updates the paymentDetails state.
 * @param       {Function}      onSavePaymentDetails        Function to save the Payment Details changes.
 * @returns     {JSX.Element}                               The component markup.
 */
function PaymentDetails() {
    const user = useSelector((state) => state.login);
    const customer = useSelector((state) => state.customer);
    const [showAlert, setShowAlert] = useState(false);
    const [needsActivityCreation, setNeedsActivityCreation] = useState(false);
    const [message, setMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const loading = customer?.getPaymentsLoader;

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') 
            return;
        
        setShowAlert(false);
        setTimeout(() => { 
            setMessage('');
            setAlertType('');
        }, 2000);
       
    };


    const handleSavePaymentDetails = (cta, paymentMethodType) => {
        handleTokenizationRequest();
        dispatch(new CustomerAction().setPaymentChangeInfo(cta));
        !new SegmentAnalyticsService().trackChangePaymentMethod(user, customer.customer.account, cta, paymentMethodType);
    };

    const handleTokenizationRequest = () => {
        dispatch(new CustomerAction().createPaymentToken());
    };

    // Hook to check Alert messages by checking the query params
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const success = query.get('payment_details_updated');

        if (success === 'true') {
            setMessage('Your payment details have been updated successfully.');
            setAlertType('success');
            setNeedsActivityCreation(true);
            !new SegmentAnalyticsService().trackSuccessfulChangePaymentMethod(user, customer.customer.account, customer.cta);
        } else if (success === 'false') {
            setMessage('Sorry, your details were not updated. Please try again or contact us via Chat or contact our Customer Care team on 1300 734 318.');
            setAlertType('error');
            !new SegmentAnalyticsService().trackFailedChangePaymentMethod(user, customer.customer.account, customer.cta);
        } else 
            return;

        setShowAlert(true);

        // Delete the query param from the URL
        query.delete('payment_details_updated');
        history.replace({
            search: query.toString(),
        });
    }, [location]);

    // Hook to show error message when the tokenisation request has failed
    useEffect(() => {
        if (customer.tokenRequestFailed) {
            setMessage('Sorry, your details were not updated. Please try again or contact us via Chat or contact our Customer Care team on 1300 734 318.');
            setAlertType('error');
            setShowAlert(true);
            dispatch(new CustomerAction().resetPaymentTokenReduxState());
        }
    }, [customer.tokenRequestFailed]);

    // Hook called when payment details are fetched and Activity needs to be created
    useEffect(() => {
        if (!objectIsEmpty(customer.payments) && needsActivityCreation)
            new CustomerAction().paymentMethodUpdated();
    }, [customer.payments]);

    const renderPaymentMethod = () => {
        const { payments } = customer;
        return (
            <React.Fragment>
                <Grid container className={classes.grid} spacing={1}>
                    { loading && <LoadingSkeleton /> }
                    { 
                        !loading && 
                        hasNoDefaultPaymentMethod(payments) && 
                        <NoDefaultPaymentMethod /> 
                    }
                    { 
                        !loading && 
                        hasDirectDebitPaymentMethod(payments) && 
                        <DirectDebitPaymentMethod paymentDetails={payments} handleSavePaymentDetails={handleSavePaymentDetails} /> 
                    }
                    { 
                        !loading && 
                        hasCreditCardPaymentMethod(payments) && 
                        <CreditCardPaymentMethod paymentDetails={payments} handleSavePaymentDetails={handleSavePaymentDetails} /> 
                    }
                    
                    { !loading && (hasDirectDebitPaymentMethod(payments) || hasCreditCardPaymentMethod(payments)) &&
                        <Typography gutterBottom align="left" className={classes.note}>
                            <Box mb={4}>
                                <a className={classes.anchor} href="https://auhelpcentre.corelogic.com.au/hc/en-au/articles/4403711082767-How-do-I-update-my-payment-details-" target="_blank" rel="noopener noreferrer">
                                    Want to add a payment method other than credit card?
                                </a>
                            </Box>
                            <p>
                                By updating my credit card details in the CoreLogic Payment Portal and clicking 'Add New Credit Card' or 'Change to Credit Card' I agree 
                                and authorise RP Data Pty Ltd to make successive withdrawals from the credit card provided in accordance with the <a href="https://www.corelogic.com.au/about-us/terms-and-conditions#PartK" target="_blank" rel="noopener noreferrer">credit card terms and conditions.</a>
                            </p>
                        </Typography>
                    }
                    
                </Grid>
            </React.Fragment>
        );
    };

    return (
        <Box mb={7}>
            <Card className={classes.card}>
                <CardHeader
                    className={classes.cardHeader}
                    title={
                        <span className={`card-header-title ${classes.disableTextTranform}`}>
                            <Grid container>
                                <Grid item xs={1}>
                                    <CreditCard style={{ margin: '-4px 5px -4px 0px', color: '#38424A' }}/>
                                </Grid>
                                <Grid item xs={11}>
                                    <Box ml={1}>Manage your payment details</Box>
                                </Grid>
                            </Grid>
                        </span>
                    }
                />
                <CardContent>
                    {renderPaymentMethod()}
                </CardContent>
            </Card>

            <Snackbar className="alert-toast" open={showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertType}>
                    {message}
                </Alert>
            </Snackbar>
        </Box>

    );
}

export default PaymentDetails;
