import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, FormControl, InputLabel, Tooltip, Paper, FormHelperText, CircularProgress, NativeSelect, Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import BootstrapInput from '../general/bootstrapInput';
import { getSingleLineAddress } from '../../../controller/services/accountService';

const useStyles = makeStyles(() => ({
    asterisk: {
        color: '#E92300',
    },
    addressContainer: {
        textAlign: 'left',
    },
    reportAddress: {
        paddingBottom: '0px !important',
        marginTop: '8px',
    },
    cancelManualAddressMode: {
        textAlign: 'left',
        padding: '0px 0px 0px 32px !important',
        marginTop: '-10px',
    },
    label: {
        minWidth: '420px',
    },
}));


const AddressInput = ({ 
    address,
    addressSuggestions,
    addressType,
    validator,
    singleLineAddress,
    handleManualAddressChange,
    handleAutocompleteAddressChange,
    handleAddressSelection,
    setManualAddressEntryValidator,
    cancelManualAddressMode,
    resetComponent,
    readOnly,
}) => {
    const classes = useStyles();
    const addressValidator = validator[addressType];
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [isManualAddress, setIsManualAddress] = useState(false);

    const onAutcompleteAddressChange = (event) => {
        const { value } = event.target;
        if (value === getSingleLineAddress(address)) setShowSuggestions(false);
        if (value.length >= 3) setShowSuggestions(true);
            
        handleAutocompleteAddressChange(event, address, addressType);
    };

    const onAddressSelection = (selectedAddress) => {
        setShowSuggestions(false);
        handleAddressSelection(selectedAddress, addressType);
    };

    useEffect(() => {
        if (resetComponent) {
            setShowSuggestions(false);
            setIsManualAddress(false);
        }
    }, [resetComponent]);

    const jsxForAutocompleteAddress = () => {
        const isReportAddress = addressType === 'reportAddress';

        return (
            <React.Fragment>
                <Grid sm={12} xs={12} 
                    item 
                    justifyContent="left"
                    className={`${classes.addressContainer} ${isReportAddress ? classes.reportAddress : ''}`}>
                    <FormControl error={addressValidator.singleLineAddress.error}>
                        <InputLabel id="account-single-line-address-label" shrink className={`input-field-label ${classes.label}`}>
                            {isReportAddress ? 'Organisation Address to appear on Reports' : 'Address to appear on Invoices' }
                            <span className={classes.asterisk}>*</span>
                            
                            <Tooltip 
                                title={isReportAddress ? 'This is your primary organisation address and will be shown on all RP Data generated reports eg CMA. This address will also be displayed on invoices we email to you unless you have specified otherwise.' : 'This address will be shown on all RP Data generated invoices we email to our Payments contact.'} leaveTouchDelay={3000} enterTouchDelay={50}>
                                <ErrorIcon className="custom-tooltip" />
                            </Tooltip>
                        </InputLabel>
                        { !readOnly && (
                            <BootstrapInput
                                id="account-single-line-address"
                                error={addressValidator.singleLineAddress.error}
                                value={singleLineAddress}
                                onChange={onAutcompleteAddressChange} name="singleLineAddress"
                                autoComplete="nope"
                            />
                        )}
                        {
                            addressValidator.singleLineAddress.error &&
                            <FormHelperText>Please input your address or click 'Enter address manually.</FormHelperText>
                        }
                    </FormControl>
                    { readOnly && (
                        <Typography variant="body1" gutterBottom className="text-left typography">
                            { singleLineAddress }
                            { isReportAddress && <Box my={3}></Box> }
                        </Typography>
                    )}
                    {
                        showSuggestions &&
                        <Paper square className="addressList">
                            {
                                addressSuggestions && addressSuggestions?.length > 0 &&
                                addressSuggestions?.map((addressLine, index) => (
                                    <div className="addressListItem" key={index} onClick={() => {
                                        onAddressSelection(addressLine);
                                    }}>
                                        {addressLine.suggestion}
                                    </div>
                                ))
                            }
                            {
                                addressSuggestions && addressSuggestions?.length === 0 &&
                                <div className="addressListItem" onClick={() => {
                                    setShowSuggestions(false);
                                    setManualAddressEntryValidator(addressType);
                                    setIsManualAddress(true);
                                }}>
                                    No results found. Enter address manually.
                                </div>
                            }
                            {
                                addressSuggestions === null &&
                                <div className="addressListItem">
                                    Fetching matching results <CircularProgress size={20} />
                                </div>
                            }
                        </Paper>
                    }
                    { !readOnly && (
                        <Box ml={2} mt={1}>
                            <a className="address-state-link"
                                onClick={() => {
                                    setManualAddressEntryValidator(addressType);
                                    setIsManualAddress(true);
                                }}>Enter address details manually
                            </a>

                        </Box>
                    )}
                </Grid>
            </React.Fragment>
        );
    };

    const jsxForManualAddress = () => {
        const isReportAddress = addressType === 'reportAddress';
        const addressLabel = isReportAddress ? 'Organisation' : 'Invoice';

        return (
            <React.Fragment>
                <Grid item sm={6} xs={12}>
                    <FormControl error={addressValidator.addressLine.error}>
                        <InputLabel id="account-address-line-1-label" shrink className="input-field-label">
                            {addressLabel} Street Address <span className={classes.asterisk}>*</span>
                            <Tooltip title="This is your primary organisation address and will be 
                                            shown on all RP Data generated reports eg CMA. This address will also be displayed 
                                            on invoices we email to you unless you have specified otherwise." leaveTouchDelay={3000} enterTouchDelay={50}>
                                <ErrorIcon className="custom-tooltip" />
                            </Tooltip>
                        </InputLabel>
                        <BootstrapInput
                            id="account-address-line-1"
                            value={(address?.addressLine)}
                            error={addressValidator.addressLine.error} onChange={(event) => handleManualAddressChange(event, addressType)} name="addressLine"
                            autoComplete="nope"
                        />
                        {
                            addressValidator.addressLine.error &&
                            <FormHelperText>Please enter your {addressLabel}'s Address Line eg 1 Queen St</FormHelperText>
                        }
                    </FormControl>
                </Grid>

                <Grid item sm={6} xs={12}>
                    <FormControl error={addressValidator.city.error}>
                        <InputLabel id="account-address-city-label" shrink className="input-field-label">
                            {addressLabel} Suburb or Town <span className={classes.asterisk}>*</span>
                        </InputLabel>
                        <BootstrapInput
                            id="account-address-city" value={address?.city}
                            error={addressValidator.city.error} onChange={(event) => handleManualAddressChange(event, addressType)} name="city"
                            autoComplete="nope"
                        />
                        {
                            addressValidator.city.error &&
                            <FormHelperText>Please enter your suburb.</FormHelperText>
                        }
                    </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <FormControl error={addressValidator.postalCode.error}>
                        <InputLabel id="account-address-postalCode-label" shrink className="input-field-label">
                            {addressLabel} Postal Code <span className={classes.asterisk}>*</span>
                        </InputLabel>
                        <BootstrapInput
                            id="account-address-postalCode" value={address?.postalCode}
                            error={addressValidator.postalCode.error} onChange={(event) => handleManualAddressChange(event, addressType)} name="postalCode"
                            autoComplete="nope"
                        />
                        {
                            addressValidator.postalCode.error &&
                            <FormHelperText>Please enter your postcode eg 4000</FormHelperText>
                        }
                    </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>

                    <FormControl style={{ 'width': '-webkit-fill-available' }} error={addressValidator.state.error}>
                        <InputLabel id="account-address-state-label" className="input-field-label">
                            {addressLabel} State <span className={classes.asterisk}>*</span>
                        </InputLabel>
                        <NativeSelect
                            labelId="account-address-state-label"
                            id="account-address-state1" value={address?.state ? address.state : 'ACT'}
                            onChange={(event) => handleManualAddressChange(event, addressType)} name="state"
                            label="Organisation State *"
                            error={addressValidator.state.error}
                            autoWidth
                            input={<BootstrapInput autoComplete="nope" />}
                        >
                            <option value="ACT">ACT</option>
                            <option value="NSW">NSW</option>
                            <option value="NT">NT</option>
                            <option value="QLD">QLD</option>
                            <option value="SA">SA</option>
                            <option value="VIC">VIC</option>
                            <option value="WA">WA</option>
                            <option value="TAS">TAS</option>
                        </NativeSelect>
                        {
                            addressValidator.state.error &&
                            <FormHelperText>Please select your state from the drop down.</FormHelperText>
                        }
                    </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <FormControl>
                        <InputLabel id="account-address-country-label" shrink className="input-field-label">
                            {addressLabel} Country <span className={classes.asterisk}>*</span>
                        </InputLabel>
                        <BootstrapInput
                            id="account-address-country" value={'AU'}
                            disabled onChange={(event) => handleManualAddressChange(event, addressType)} name="country"
                            autoComplete="nope"
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={12} className={`${classes.cancelManualAddressMode}`}>
                    <a className="address-state-link"
                        onClick={() => {
                            cancelManualAddressMode(addressType);
                            setIsManualAddress(false);
                        }}>Cancel manual address entry  
                    </a>
                </Grid>
                <Box my={2}></Box>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {!isManualAddress && jsxForAutocompleteAddress()}
            {!readOnly && isManualAddress && jsxForManualAddress()}
        </React.Fragment>
    );
};

AddressInput.propTypes = {
    addressType: PropTypes.string.isRequired,
    resetComponent: PropTypes.bool.isRequired,
    validator: PropTypes.object.isRequired,
    address: PropTypes.object.isRequired,
    addressSuggestions: PropTypes.array.isRequired,
    singleLineAddress: PropTypes.string.isRequired,
    handleManualAddressChange: PropTypes.func.isRequired,
    handleAutocompleteAddressChange: PropTypes.func.isRequired,
    handleAddressSelection: PropTypes.func.isRequired,
    setManualAddressEntryValidator: PropTypes.func.isRequired,
    cancelManualAddressMode: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
};

export default AddressInput;