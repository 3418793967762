export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const USER_SESSION_REQUEST = 'USER_SESSION_REQUEST';
export const USER_SESSION_SUCCESS = 'USER_SESSION_SUCCESS';
export const USER_SESSION_FAILURE = 'USER_SESSION_FAILURE';

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';

export const USER_EXPIRY_SESSION = 'USER_EXPIRY_SESSION';
export const RESET_CUSTOMER = 'RESET_CUSTOMER';

export const UM_PROFILE_REQUEST = 'UM_PROFILE_REQUEST';
export const UM_PROFILE_SUCCESS = 'UM_PROFILE_SUCCESS';
export const UM_PROFILE_FAILURE = 'UM_PROFILE_FAILURE';

export const USER_VERIFCATION_REQUEST = 'USER_VERIFCATION_REQUEST';
export const USER_VERIFCATION_SUCCESS = 'USER_VERIFCATION_SUCCESS';
export const USER_VERIFCATION_FAILURE = 'USER_VERIFCATION_FAILURE';


export const GET_PAYMENT_METHOD_REQUEST = 'GET_PAYMENT_METHOD_REQUEST';
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS';
export const GET_PAYMENT_METHOD_FAILURE = 'GET_PAYMENT_METHOD_FAILURE';

export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';

export const GET_ACCOUNT_AND_CONTACT_REQUEST = 'GET_ACCOUNT_AND_CONTACT_REQUEST';
export const GET_ACCOUNT_AND_CONTACT_SUCCESS = 'GET_ACCOUNT_AND_CONTACT_SUCCESS';
export const GET_ACCOUNT_AND_CONTACT_FAILURE = 'GET_ACCOUNT_AND_CONTACT_FAILURE';

export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILURE = 'UPDATE_ACCOUNT_FAILURE';

export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE';

export const CREATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
export const CREATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const CREATE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE';

export const DELETE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
export const DELETE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE';

export const UPDATE_CONTACT_REQUEST = 'UPDATE_CONTACT_REQUEST';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_FAILURE = 'UPDATE_CONTACT_FAILURE';

export const GET_ADDRESS_SUGGESTS_REQUEST = 'GET_ADDRESS_SUGGESTS_REQUEST';
export const GET_ADDRESS_SUGGESTS_SUCCESS = 'GET_ADDRESS_SUGGESTS_SUCCESS';
export const GET_ADDRESS_SUGGESTS_FAILURE = 'GET_ADDRESS_SUGGESTS_FAILURE';

export const GET_ADDRESS_DETAILS_REQUEST = 'GET_ADDRESS_DETAILS_REQUEST';
export const GET_ADDRESS_DETAILS_SUCCESS = 'GET_ADDRESS_DETAILS_SUCCESS';
export const GET_ADDRESS_DETAILS_FAILURE = 'GET_ADDRESS_DETAILS_FAILURE';

export const CREATE_ACTIVITY_REQUEST = 'CREATE_ACTIVITY_REQUEST';
export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS';
export const CREATE_ACTIVITY_FAILURE = 'CREATE_ACTIVITY_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const ADD_ATTACHMENT_REQUEST = 'ADD_ATTACHMENT_REQUEST';
export const ADD_ATTACHMENT_SUCCESS = 'ADD_ATTACHMENT_SUCCESS';
export const ADD_ATTACHMENT_FAILURE = 'ADD_ATTACHMENT_FAILURE';

export const CREATE_TOKEN_REQUEST = 'CREATE_TOKEN_REQUEST';
export const CREATE_TOKEN_SUCCESS = 'CREATE_TOKEN_SUCCESS';
export const CREATE_TOKEN_FAILURE = 'CREATE_TOKEN_FAILURE';

export const SET_PERMISSIONS_SUCCESS = 'SET_PERMISSIONS_SUCCESS';

export const SET_PAYMENT_CHANGE_INFO_SUCCESS = 'SET_PAYMENT_CHANGE_INFO_SUCCESS';

export const RESET_TOKEN_REQUEST = 'RESET_TOKEN_REQUEST';

export const SET_ACCOUNT_SUCCESS = 'SET_ACCOUNT_SUCCESS';