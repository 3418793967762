import React from 'react';
import {
    Grid,
    Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { showLabelSkelton, readOnlyTextSkelton } from '../../../reusables/Skeletons';

const useStyles = makeStyles(() => ({
    grid: {
        marginBottom: '10px',
        paddingRight: '60px !important',
    },
}));


/**
 * JSX for Payment details loading skeleton
 *
 * @returns {JSX.Element}                       The component markup.
 */

const LoadingSkeleton = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid item xs={12} md={6} className={classes.grid}>
                <Box my={2}>
                    {showLabelSkelton()}
                    {readOnlyTextSkelton()}
                </Box>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid}>
                <Box my={2}>
                    {showLabelSkelton()}
                    {readOnlyTextSkelton()}
                </Box>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid}>
                <Box my={1}>
                    {showLabelSkelton()}
                    {readOnlyTextSkelton()}
                </Box>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid}>
                <Box my={1}>
                    {showLabelSkelton()}
                    {readOnlyTextSkelton()}
                </Box>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid}>
                <Box my={1}>
                    {showLabelSkelton()}
                    {readOnlyTextSkelton()}
                </Box>
            </Grid>
        </React.Fragment>
    );
};

export default LoadingSkeleton;
  