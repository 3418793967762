import React from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import CustomerAdminPortalPage from './views/CustomerAdminPortalPage';
import UserValidationPage from './views/UserValidationPage';
import PageNotFound from './views/PageNotFound';
import SelfServiceNAPage from './views/SelfServiceNAPage';
import AccountSelectionPage from './views/AccountSelectionPage';
import HomePage from './views/HomePage';

import Header from './views/components/navigation/header';
import Footer from './views/components/navigation/footer';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        fontFamily: 'Poppins, "Open Sans", sans-serif',
    },
    palette: {
        primary: {
            main: '#2196f3',
        },
    },
});

const HEALTH_INFO = {
    'status': 
        {
            'applicationStatus': 
            {
                'name': 'Self Service UI',
                'type': 'Application',
                'status': 'GREEN', 
                'statusDetails': 'OK',
            }, 
            'requestedDateTime': new Date(),
        },
};
function App() {

    return (
        <MuiThemeProvider theme={theme}>
            <div className="App">
                <Header/>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/"
                            render={
                                (props) =>
                                    <HomePage
                                        {...props}
                                    />
                            } />
                        <Route exact path="/email_validation"
                            render={
                                (props) =>
                                    <UserValidationPage
                                        {...props}
                                    />
                            } />
                        <Route exact path="/self-service-na"
                            render={
                                () =>
                                    <SelfServiceNAPage/>
                            } />
                        <Route exact path={['/accounts']}
                            render={
                                () =>
                                    <AccountSelectionPage />
                            } />
                        <Route path="/private/status">
                            <p style={{margin: '120px 60px'}}>
                                {JSON.stringify(HEALTH_INFO, null, 2) }
                            </p>
                        </Route>
                        <Route exact path={['/portal/:myProfile']}
                            render={
                                (props) => 
                                    <CustomerAdminPortalPage
                                        {...props}
                                    />
                            } />
                        <Route component={PageNotFound} />
                    </Switch>
                </BrowserRouter>
                <div className="push"></div>
                <Footer/>
            </div>
        </MuiThemeProvider>
    );
}

export default App;
