import React from 'react';
import { Box } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import parse from 'html-react-parser';
import { objectIsEmpty, sanitizeAddressType } from './helper';
import { SegmentAnalyticsService } from '../services';
import { LoginAction, CustomerAction } from '../actions';
import { store } from '../../store';

import { FIELD_INFO_TYPES } from '../constants/accountDetails';

/************************************
 *  Address related code
 ************************************/

const isReportAndInvoiceAddress = (address, addresses) => {
    if (address.isPrimary === true && address.addressType?.includes('BILL_TO')) return true;
    if (addresses.length === 1) return true;
    
    const invoicingAddresses = addresses.filter((adrs) => adrs.addressType?.includes('BILL_TO'));
    if (invoicingAddresses.length === 0) return true;

    return false;
};

const isReportAddress = (address) => address.isPrimary === true;

const isInvoiceAddress = (address) => address.isPrimary !== true && address.addressType?.includes('BILL_TO');

const isAddressValid = (addressValidator) => Object.keys(addressValidator).filter((key) => addressValidator[key].error === true).length === 0;

const getReportAddress = (addresses = []) => {
    for (const address of addresses)
        if (isReportAddress(address)) return address;
    return null;
};

const getInvoiceAddress = (addresses = []) => {
    for (const address of addresses)
        if (isInvoiceAddress(address)) return address;
    return null;
};

const getAddressFromReducer = (addressType = 'reportAddress', customer) => {
    const { addresses } = customer.customer.account;
    for (const address of addresses) {
        if (addressType === 'reportAddress' && isReportAddress(address)) return address;
        if (addressType === 'invoiceAddress' && isInvoiceAddress(address)) return address;
    }
};

const getSingleLineAddress = (address) => {
    if (address) {
        const { addressLine, city, state, postalCode } = address;
        return (`${addressLine ? addressLine : ''} ` + 
            `${city ? city : ''} ` +
            `${state ? state : ''} ` +
            `${postalCode ? postalCode : ''} `).replace(/ +(?= )/g, '');
    } else 
        return '';
};

const getAddressesToBeUpdated = (reportAddress, invoiceAddress, updatedFields) => {
    let addressesToBeUpdated = [];
    const updatedKeys = Object.keys(updatedFields);

    if (updatedKeys.includes('reportAddress') && reportAddress.addressNumber) addressesToBeUpdated.push(reportAddress);
    if (updatedKeys.includes('invoiceAddress') && invoiceAddress.addressNumber) addressesToBeUpdated.push(invoiceAddress);

    // Filter out empty objects or null addresses
    addressesToBeUpdated = addressesToBeUpdated.filter((address) => address !== null && !objectIsEmpty(address));

    return addressesToBeUpdated;
};

const getAddressesToBeCreated = (addresses) => {
    let addressesToBeCreated = [];
    for (const address of addresses)
        if (address !== null && !address?.addressNumber)
            addressesToBeCreated.push(address);

    // Filter out empty objects or null addresses
    addressesToBeCreated = addressesToBeCreated.filter((address) => address !== null && !objectIsEmpty(address));
    
    return addressesToBeCreated;
};

const getAddressesToBeDeleted = (preDeletionAddresses, addresses) => {
    if (!preDeletionAddresses) return [];

    let addressesToBeDeleted = [];
    for (const preDeletionAddress of preDeletionAddresses) {
        const addressNumberToMatch = preDeletionAddress.addressNumber;
        let noMatchFound = true;

        for (const address of addresses) {
            if (!address) continue;
            if (address.addressNumber === addressNumberToMatch) noMatchFound = false;
        }

        // If there was at least one match for the `addressNumberToMatch` then `noMatchFound` will be false
        if (noMatchFound) addressesToBeDeleted.push(preDeletionAddress);
    }

    // Filter out empty objects or null addresses
    addressesToBeDeleted = addressesToBeDeleted.filter((address) => address !== null && !objectIsEmpty(address));

    // Retain only the `addressNumber` key in the objects, since it's the only permitted key in the API
    const keysToKeep = ['addressNumber'];
    addressesToBeDeleted = addressesToBeDeleted.map((address) => Object.assign({}, ...keysToKeep.map((key) => ({[key]: address[key]}))));

    return addressesToBeDeleted;
};

const validateReportAddress = (reportAddress, isReportAddressTheSameForInvoice) => {
    if (objectIsEmpty(reportAddress).length === 0) return reportAddress;

    if (reportAddress && !isReportAddressTheSameForInvoice) {
        const addressType = reportAddress['addressType'];
        reportAddress['addressType'] = addressType ? addressType.replace('BILL_TO', '') : addressType;
    }

    if ('isPrimary' in reportAddress) delete reportAddress['isPrimary'];
    if (reportAddress?.addressLine) {
        reportAddress['address1'] = reportAddress['addressLine'];
        delete reportAddress['addressLine'];
    } 
    if (!reportAddress?.addressType || reportAddress?.addressType?.length === 0)
        reportAddress.addressType = 'SELL_TO';
    if (isReportAddressTheSameForInvoice && !reportAddress.addressType?.includes('BILL_TO'))
        reportAddress.addressType += ',BILL_TO';
    
    if (reportAddress?.addressType)
        reportAddress.addressType = sanitizeAddressType(reportAddress.addressType);

    return reportAddress;
};

const validateInvoiceAddress = (invoiceAddress, isReportAddressTheSameForInvoice) => {
    if (objectIsEmpty(invoiceAddress)) return invoiceAddress;

    if (invoiceAddress && !isReportAddressTheSameForInvoice) {
        invoiceAddress['addressType'] = 'BILL_TO';
        invoiceAddress['country'] = 'AU';
    }
    if ('isPrimary' in invoiceAddress) delete invoiceAddress['isPrimary'];
    if (invoiceAddress?.addressLine) {
        invoiceAddress['address1'] = invoiceAddress['addressLine'];
        delete invoiceAddress['addressLine'];
    }
    if (invoiceAddress?.addressType === '') invoiceAddress.addressType = null; 

    return invoiceAddress;
};

const hasAddressChanges = (updatedFields) => {
    const updatedKeys = Object.keys(updatedFields);
    const addressKeys = ['reportAddress', 'invoiceAddress'];
    for (const key of updatedKeys)
        if (addressKeys.includes(key)) return true;
       
    return false;
};

const hasOnlyAddressChanges = (updatedFields) => {
    const updatedKeys = Object.keys(updatedFields);
    const addressKeys = ['reportAddress', 'invoiceAddress'];
    for (const key of updatedKeys)
        if (!addressKeys.includes(key)) return false;
    return true;
};

/************************************
 *  Contacts related code
 ************************************/

const primaryAndPaymentContactIsSame = (account = {}) => {
    const { primaryContactPartyId, organizationDEOXNewContactForPaymentIdC } = account;
    return primaryContactPartyId === organizationDEOXNewContactForPaymentIdC;
};

const getPrimaryContact = (account = {}) => {
    const { primaryContactPartyId, contacts = [] } = account;
    return contacts.find((contact) => contact.partyId === primaryContactPartyId);
};

const getPaymentContact = (account = {}) => {
    const { organizationDEOXNewContactForPaymentIdC, contacts = [] } = account;
    return contacts.find((contact) => contact.partyId === organizationDEOXNewContactForPaymentIdC);
};


const getPrimaryContactEmailAddress = (account = {}) => {
    const primaryContact = getPrimaryContact(account);
    return primaryContact ? primaryContact.emailAddress : '';
};

const getPaymentContactEmailAddress = (account = {}) => {
    const paymentContact = getPaymentContact(account);
    return paymentContact ? paymentContact.emailAddress : '';
};


const getContactsToBeUpdated = (updatedFields, account) => {
    const contactsToBeUpdated = [];
    
    if ('primaryContactEmailAddress' in updatedFields) {
        const contactDetails = getPrimaryContact(account);
        contactsToBeUpdated.push({
            accountNumber: contactDetails.accountPartyNumber,
            partyNumber: contactDetails.partyNumber,
            emailAddress: account.primaryContactEmailAddress,
        });
    }

    if ('paymentContactEmailAddress' in updatedFields) {
        const contactDetails = getPaymentContact(account);
        contactsToBeUpdated.push({
            accountNumber: contactDetails.accountPartyNumber,
            partyNumber: contactDetails.partyNumber,
            emailAddress: account.paymentContactEmailAddress,
        });
    }

    return contactsToBeUpdated;
};

/************************************
 *  Content for Fields Info Modal
 ************************************/

const trackLinkClick = (fieldType) => {
    !new SegmentAnalyticsService().trackField('Click Customer Care Support', fieldType);
};

const getFieldsInfoTitle = (fieldType) => {
    const modalTitle = `Want to change your ${fieldType}`;
    return (
        <Box>
            <span>{modalTitle}</span>
        </Box>
    );
};

const getContactUsHtmlElement = (fieldType) => {
    const contactUsLink = 'https://www.corelogic.com.au/contact-us';
    
    return (
        <a id="contact-us" 
            href={contactUsLink}
            onClick={(event) => {
                event.preventDefault();
                trackLinkClick(fieldType);
                window.open(contactUsLink, '_blank', 'noreferrer');
            }}>
            Customer Care Support Team
        </a>
    );
};

const getFieldsInfoBody = (fieldType) => {
    let modalBody = `If you wish to change the ${fieldType} of your organisation, please submit a request to our <a id='contact-us'></a>.<br/><br/>`;

    switch (fieldType) {
    case FIELD_INFO_TYPES.ACCOUNT_NAME:
        modalBody += 'This request will need to be confirmed by users who hold the following account status privileges: <ul><li>Primary Contact</li><li>Admin</li></ul>';
        break;
    case FIELD_INFO_TYPES.BUSINESS_NAME:
        modalBody += 'This request will require the completion of a "Change of Ownership" form to document the change of your Business Name for our records.';
        break;
    case FIELD_INFO_TYPES.ABN:
        modalBody += 'This request will require the completion of a "Change of Ownership" form to document the change of your ABN for our records.';
        break;
    case FIELD_INFO_TYPES.PRIMARY_CONTACT:
        modalBody += `This request will need to be requested and signed off by a user who holds one of the following account status privileges:
                        <ul>
                            <li>Outgoing Primary Contact (if available)</li>
                            <li>Incoming Primary Contact</li>
                            <li>at least one Admin</li>
                        </ul>
                    `;
        break;
    case FIELD_INFO_TYPES.PAYMENT_CONTACT:
        modalBody += `This request will need to be requested and signed off by a user who holds one of the following account status privileges:
                        <ul>
                            <li>Primary Contact</li>
                            <li>Admin</li>
                        </ul>
                    `;
        break;
    }

    return (
        <Box style={{fontSize: '17px'}}>
            <span>
                {
                    parse(
                        modalBody, 
                        {
                            replace: ({ attribs }) => {
                                if (attribs?.id === 'contact-us') return getContactUsHtmlElement(fieldType);
                            },
                        },
                    )
                }
            </span>
        </Box>
    );
};



/************************************
 *  Confirm Changes related code
 ************************************/

const getConfirmChangesModalTitle = () => {
    return (
        <Box>
            <WarningIcon style={{margin: '0px 15px -5px 0px'}}/>
            <span>Save changes?</span>
        </Box>
    );
};

const getConfirmChangesModalBody = (updatedFields, accountBeforeUpdate, reportAddressAfterUpdate, invoiceAddressAfterUpdate) => {
    if (objectIsEmpty(updatedFields)) return;
    return (
        <Box style={{fontSize: '17px'}}>
            <Box mb={2}>Do you wish to confirm the changes you have made?</Box>
            {getAccountChanges(updatedFields, accountBeforeUpdate, reportAddressAfterUpdate, invoiceAddressAfterUpdate)}
        </Box>
    );
};

const getChangesForOrganisationEmail = (accountBeforeUpdate, updatedFields) => {
    const beforeChange = accountBeforeUpdate.organizationDEOXAccountEmailC;
    const afterChange = updatedFields.organizationDEOXAccountEmailC;
    const description = `The organsation email address will be changed from ${beforeChange} to <b>${afterChange}.</b>`;
    return htmlGeneratorForChanges('Organisation Email', description);
};

const getChangesForOrganisationPhone = (accountBeforeUpdate, updatedFields) => {
    const phoneAreaCodeAfterChange = ('phoneAreaCode' in updatedFields) ? updatedFields.phoneAreaCode : (accountBeforeUpdate.phoneAreaCode || '');
    const phoneNumberAfterChange = ('phoneNumber' in updatedFields) ? updatedFields.phoneNumber : accountBeforeUpdate.phoneNumber;

    const beforeChange = `${accountBeforeUpdate.phoneAreaCode || ''} ${accountBeforeUpdate.phoneNumber}`;
    const afterChange = `${phoneAreaCodeAfterChange} ${phoneNumberAfterChange}`;
    const description = `The organsation phone will be changed from ${beforeChange} to <b>${afterChange}</b>.`;
    return htmlGeneratorForChanges('Organisation Phone', description);
};

const getChangesForPrimaryContactEmailAddress = (accountBeforeUpdate, updatedFields) => {
    const title = primaryAndPaymentContactIsSame(accountBeforeUpdate) ? 'Primary & Payments Contact Email' : 'Primary Contact Email';
    const beforeChange = accountBeforeUpdate.primaryContactEmailAddress;
    const afterChange = updatedFields.primaryContactEmailAddress;
    const description = `The ${title.toLowerCase()} address will be changed from ${beforeChange} to <b>${afterChange}.</b>`;
    return htmlGeneratorForChanges(title, description);
};

const getChangesForPaymentContactEmailAddress = (accountBeforeUpdate, updatedFields) => {
    const title = 'Payments Contact Email';
    const beforeChange = accountBeforeUpdate.paymentContactEmailAddress;
    const afterChange = updatedFields.paymentContactEmailAddress;
    const description = `The ${title.toLowerCase()} address will be changed from ${beforeChange} to <b>${afterChange}.</b>`;
    return htmlGeneratorForChanges(title, description);
};

const getChangesForOrganisationReportAddress = (accountBeforeUpdate, updatedFields, reportAddressAfterUpdate) => {
    let description = '';
    let beforeChange = getReportAddress(accountBeforeUpdate.addresses);
    if (beforeChange) beforeChange = getSingleLineAddress(beforeChange);
    const afterChange = getSingleLineAddress(updatedFields.reportAddress);

    if (afterChange?.split(' ').join('').length > 0) 
        description = `The organsation address will be changed from ${beforeChange} to <b>${getSingleLineAddress(reportAddressAfterUpdate)}</b><br /><br />
                         The new address will appear on all RP Data generated reports.`;
    
    return description.length === 0 ? description : htmlGeneratorForChanges('Organisation Address', description);
};

const getChangesForOrganisationInvoiceAddress = (accountBeforeUpdate, updatedFields, invoiceAddressAfterUpdate, reportAddressAfterUpdate) => {
    let description = '';
    let beforeChange = getInvoiceAddress(accountBeforeUpdate.addresses);
    if (beforeChange) beforeChange = getSingleLineAddress(beforeChange);
    const afterChange = getSingleLineAddress(invoiceAddressAfterUpdate);
    const reportAddress = getSingleLineAddress(reportAddressAfterUpdate);

    // When an InvoiceAddress was deleted i.e. when `Organisation Address To Appear On Invoices` got ticked
    if (updatedFields.invoiceAddress.deleted)
        description = `Your Organisation Address will be shown on RP Data generated invoices.<br /><br />
                       The address to appear on invoices has changed from ${beforeChange} to <b>${reportAddress}</b>`;
    // When an existing InvoiceAddress was updated
    else if (beforeChange && afterChange)
        description = `The invoice address will be changed from ${beforeChange} to <b>${afterChange}</b><br /><br />
                       The new address will appear on all RP Data generated invoices.`;
    // When an InvoiceAddress is newly entered i.e. when `Organisation Address To Appear On Invoices` got un-ticked
    else
        description = `Your Organisation Address will not be shown on RP Data generated invoices.<br /><br />
                       The address to appear on invoices has changed from ${reportAddress} to <b>${afterChange}</b>`;

    return description.length === 0 ? description : htmlGeneratorForChanges('Invoice Address', description);
    
};

const getAccountChanges = (updatedFields, accountBeforeUpdate, reportAddressAfterUpdate, invoiceAddressAfterUpdate) => {
    const changesText = [];
   
    if ('organizationDEOXAccountEmailC' in updatedFields)
        changesText.push(getChangesForOrganisationEmail(accountBeforeUpdate, updatedFields));
        
    if ('phoneNumber' in updatedFields || 'phoneAreaCode' in updatedFields) 
        changesText.push(getChangesForOrganisationPhone(accountBeforeUpdate, updatedFields));

    if ('primaryContactEmailAddress' in updatedFields)
        changesText.push(getChangesForPrimaryContactEmailAddress(accountBeforeUpdate, updatedFields));

    if ('paymentContactEmailAddress' in updatedFields)
        changesText.push(getChangesForPaymentContactEmailAddress(accountBeforeUpdate, updatedFields));

    if ('reportAddress' in updatedFields) 
        changesText.push(getChangesForOrganisationReportAddress(accountBeforeUpdate, updatedFields, reportAddressAfterUpdate));

    if ('invoiceAddress' in updatedFields) 
        changesText.push(
            getChangesForOrganisationInvoiceAddress(accountBeforeUpdate, updatedFields, invoiceAddressAfterUpdate, reportAddressAfterUpdate));

    return (
        <Box>
            {changesText}
        </Box>
    );
};

const htmlGeneratorForChanges = (title, description) => {
    return (
        <Box mb={2}>
            <Box my={1}><b>{title}</b></Box>
            <Box style={{fontSize: '14px'}} dangerouslySetInnerHTML={{ __html: description}}></Box>
        </Box>
    );
};

/************************************
 *  Accounts related code
 ************************************/
const userNotEligibleForSelfService = (loginUser) => {
    const userHasZeroAccounts = loginUser.profile?.accounts?.length === 0;
    const userHasNullAccounts = loginUser.isUserLoggedIn && !loginUser.profile?.accounts;
    const userHasInvalidEmail = !loginUser.profile.userDetails?.isEmailValidated;
    return userHasInvalidEmail || userHasNullAccounts || userHasZeroAccounts;
};

const userHasSingleAccount = (loginUser) => {
    return loginUser.profile?.accounts?.length === 1;
};

const userHasMultipleAccount = (loginUser) => {
    return loginUser.profile?.accounts?.length > 1;
};

const selectedAccount = (account) => {
    const requestAccount = {
        accountNumber: account.externalAcctId,
        accountGuid: account.crmAccountGuid,
    };
    new CustomerAction().selectAccount(requestAccount)
        .then((response) => {
            if (response?.data?.ok)
                store.dispatch(new LoginAction().setAccount(requestAccount));
            else window.location.assign('/self-service-na');
        })
        .catch(() => {
            window.location.assign('/self-service-na');
        });
};

const pushToLandingPage = (loginUser, history) => {

    if (userNotEligibleForSelfService(loginUser)) {
        history.push('/self-service-na');
        return;
    }

    new SegmentAnalyticsService().trackUserActivity(loginUser);
    
    if (userHasSingleAccount(loginUser)) {
        history.push('/portal/account-and-contact');
        return;
    }

    if (userHasMultipleAccount(loginUser)) {
        history.push('/accounts');
        return;
    }
     
};

export { 
    isReportAndInvoiceAddress, 
    isReportAddress, 
    isInvoiceAddress, 
    getAddressFromReducer,
    getSingleLineAddress, 
    getAddressesToBeUpdated, 
    getAddressesToBeCreated, 
    getAddressesToBeDeleted,
    validateReportAddress,
    validateInvoiceAddress,
    hasOnlyAddressChanges,
    hasAddressChanges,
    getConfirmChangesModalTitle,
    getConfirmChangesModalBody,
    isAddressValid,
    primaryAndPaymentContactIsSame,
    getPrimaryContactEmailAddress,
    getPaymentContactEmailAddress,
    getFieldsInfoTitle,
    getFieldsInfoBody,
    getContactsToBeUpdated,
    getPrimaryContact,
    getPaymentContact,
    selectedAccount,
    pushToLandingPage,
    userNotEligibleForSelfService,
    userHasMultipleAccount,
    userHasSingleAccount,
};