import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import { format, parseISO } from 'date-fns';
import {
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Button,
    Box,
    CircularProgress,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import { formatCardNumber } from '../../../../../controller/services/helper';

const useStyles = makeStyles(() => ({
    paragraph: {
        fontSize: '16px',
    },
    typography: {
        paddingTop: '32px',
    },
    fullWidthButton: {
        width: '100% !important',
    },
    grid: {
        marginBottom: '10px',
    },
    spinner: {
        marginLeft: '10px',
    },
}));

/**
 * JSX for Credit Card Payment Method
 *
 * @param   {Object}        props                       The component properties.
 * @param   {Object}        paymentDetails              Object containing the paymentDetails data Eg: Account name, Card Number,.. etc.
 * @param   {Function}      handleSavePaymentDetails    Saves/updates payment details.
 * @returns {JSX.Element}                               The component markup.
 */

const CreditCardPaymentMethod = ({ paymentDetails, handleSavePaymentDetails }) => {
    const customer = useSelector((state) => state.customer);
    const classes = useStyles();
    const theme = useTheme();
    const smallDevice = useMediaQuery(theme.breakpoints.down('xs'));

    const {
        accountName,
        expiryDate,
        accountNumber,
        paymentMethodCode,
    } = paymentDetails || {};

    return (
        <React.Fragment>
            <Grid item xs={12} md={6} className={classes.grid}>
                <FormControl>
                    <InputLabel>
                        Payment Method
                    </InputLabel>
                </FormControl>
                <Typography className={`text-left typography ${classes.typography}`}>
                    { paymentMethodCode } Card
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid}>
                <FormControl>
                    <InputLabel>
                        Card Number
                    </InputLabel>
                </FormControl>
                <Typography className={`text-left typography ${classes.typography}`}>
                    { formatCardNumber(accountNumber) }
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid}>
                <FormControl>
                    <InputLabel>
                        Account Name
                    </InputLabel>
                </FormControl>
                <Typography className={`text-left typography ${classes.typography}`}>
                    { accountName }
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid}>
                <FormControl>
                    <InputLabel>
                        Card Expiry Date
                    </InputLabel>
                </FormControl>
                <Typography className={`text-left typography ${classes.typography}`}>
                    { (!expiryDate || expiryDate?.length < 2) && 'XX/XX'}
                    { expiryDate?.length < 7 && expiryDate.length > 2 && expiryDate }
                    { expiryDate?.length > 7 && format(parseISO(expiryDate), 'MM/yy') }
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box mt={3} mb={1}>
                    <Button variant="contained" color="primary" disabled={customer.tokenLoader} onClick={() => handleSavePaymentDetails('Add New Credit Card', '')} className={smallDevice ? classes.fullWidthButton : ''}>
                        Add New Credit Card {' '}
                        {customer.tokenLoader && <CircularProgress size={20} className={classes.spinner} />}
                    </Button>
                </Box>
            </Grid>
        </React.Fragment>
    );
};

CreditCardPaymentMethod.propTypes = {
    paymentDetails: PropTypes.object.isRequired,
    handleSavePaymentDetails: PropTypes.func.isRequired,
};

export default CreditCardPaymentMethod;
