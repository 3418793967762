import { store } from '../../store';

const isValidUser = () => isAdmin();

const isAdmin = () => {
    const reduxStore = store.getState();
    const { userDetails } = reduxStore?.login?.profile || {};
    
    return userDetails?.isAdmin === true;
};

const isPrimaryContact = () => {
    const reduxStore = store.getState();
    const contact = reduxStore?.customer?.customer?.contact || {};
    
    return contact?.isPrimaryContact === true;
};

const isPaymentContact = () => {
    const reduxStore = store.getState();
    const contact = reduxStore?.customer?.customer?.contact || {};
    
    return contact?.isPrimaryPaymentContact === true;
};

const isOnlyAdmin = () => {
    return isAdmin() && !isPrimaryContact() && !isPaymentContact();
};

const isAdminAndPrimaryOrPaymentContact = () => (isAdmin() && (isPrimaryContact() || isPaymentContact())); 

const isAdminAndPrimaryContact = () => (isAdmin() && isPrimaryContact()); 

const isAdminOrPrimaryOrPaymentContact = () => (isAdmin() || isPrimaryContact() || isPaymentContact());

const getAccountDetailsPermissions = () => {
    return {
        organisation_email: isAdminAndPrimaryOrPaymentContact() || isOnlyAdmin() || isAdmin(),
        organisation_phone: isAdminAndPrimaryOrPaymentContact() || isOnlyAdmin() || isAdmin(),
        report_address: isAdminAndPrimaryOrPaymentContact() || isOnlyAdmin() || isAdmin(),
        invoice_checkbox: isAdminAndPrimaryOrPaymentContact() || isOnlyAdmin() || isAdmin(),
        invoice_address: isAdminAndPrimaryOrPaymentContact() || isOnlyAdmin() || isAdmin(),
        primary_contact_email_address: isAdminAndPrimaryContact() || isOnlyAdmin() || isAdmin(),
        payment_contact_email_address: isAdminAndPrimaryOrPaymentContact() || isOnlyAdmin() || isAdmin(),
        save_changes: isAdminOrPrimaryOrPaymentContact() || isOnlyAdmin() || isAdmin(),
    };
};

export {
    isValidUser,
    getAccountDetailsPermissions,
};