import { combineReducers } from 'redux';
import customer from './customer';
import login from './login';
import address from './address';

const appReducer = combineReducers({
    customer,
    login,
    address,
});

const reducers = (state, action) => {
    return appReducer(state, action);
};

export default reducers;
