import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '693px',
        borderRadius: '6px',
        border: 'none',
        '&:focus': {
            outline: 'none',
        },
    },
    paper: {
        backgroundColor: '#fff',
        borderRadius: '6px',
        padding: '0px',
        border: 'none',
        '&:focus': {
            outline: 'none',
        },
    },
    header: {
        backgroundColor: '#FAFAFA',
        borderRadius: '6px',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '32px',
        display: 'flex',
        alignItems: 'flex-start',
        color: '#38424A',
        border: 'none',
    },
    body: {},
    footer: {},
    closeButton: {
        flex: 1, 
        textAlign: 'right',
        cursor: 'pointer',
    },
    marginForSmallerDevices: {
        margin: '12px',
    },
    marginForBiggerDevices: {
        margin: '0 auto',
    },
    paddingForSmallerDevices: {
        padding: '16px',
    },
    paddingForBiggerDevices: {
        padding: '16px 30px',
    },
}));

/**
 * Re-usable Modal template
 *
 * @param   {Object}        props                         The component properties.
 * @param   {Boolean}       props.isOpen                  Show/Hide the Modal.
 * @param   {Function}      props.toggleModal             Function that toggles the value for `isOpen` (Boolean) which is used to show/hide the modal.
 * @param   {JSX.Element}   props.modalHeader             JSX element to display the modal title.
 * @param   {JSX.Element}   props.modalBody               JSX element to display the modal body.
 * @param   {JSX.Element}   props.modalFooter             JSX element to display the modal footer.
 * @returns {JSX.Element}                                 The component markup.
 */

const ModalComponent = ({modalHeader, modalBody, modalFooter, isOpen, toggleModal}) => {
    const classes = useStyles();
    const theme = useTheme();
    const smallDevice = useMediaQuery(theme.breakpoints.down('xs'));
    const modalClassNames = `${classes.modal} ${(smallDevice ? classes.marginForSmallerDevices : classes.marginForBiggerDevices)}`;
    const modalHeaderClassNames = `${classes.header} ${(smallDevice ? classes.paddingForSmallerDevices : classes.paddingForBiggerDevices)}`;
    const modalBodyClassNames = `${classes.body} ${(smallDevice ? classes.paddingForSmallerDevices : classes.paddingForBiggerDevices)}`;
    const modalFooterClassNames = `${classes.footer} ${(smallDevice ? classes.paddingForSmallerDevices : classes.paddingForBiggerDevices)}`;

    return (
        <Modal
            className={modalClassNames}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 300,
            }}
            open={isOpen}
            onClose={toggleModal}
            aria-labelledby="modal-header"
            aria-describedby="modal-body"
        >
            <Fade in={isOpen}>
                <Box className={classes.paper}>
                    <Box className={modalHeaderClassNames}>
                        <Box id="modal-header" display="inline">{modalHeader}</Box>
                        <Box className={classes.closeButton} display="inline"><CloseIcon onClick={toggleModal} size={40}/></Box>
                    </Box>
                    <Box id="modal-body" className={modalBodyClassNames}>{modalBody}</Box>
                    <Box id="modal-footer" className={modalFooterClassNames}>{modalFooter}</Box>
                </Box>
            </Fade>
        </Modal>
    );
};

ModalComponent.propTypes = {
    modalHeader: PropTypes.element.isRequired,
    modalBody: PropTypes.element.isRequired,
    modalFooter: PropTypes.element,
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
};
  
export default ModalComponent;
  