import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LoginAction } from '../controller/actions';
import { pushToLandingPage } from 'controller/services/accountService';
import ProgressLoader from './components/reusables/ProgressLoader';

export default function HomePage(){
    const history = useHistory();
    const dispatch = useDispatch();
    const loginUser = useSelector((state) => state.login);
    const [profile] = useState(loginUser.profile);

    useEffect(() => {
        if (loginUser.profile)
            pushToLandingPage(loginUser, history);
    }, [loginUser.profile]);

    useEffect(() => {
        if (!profile) dispatch(new LoginAction().redirectToLoginPage());
    }, [profile]);

    return (
        <div>
            {
                (!loginUser.profile) && 
                <div className="body-wrapper">
                    <ProgressLoader text="Redirecting to login page..." />
                </div> 
            }
        </div>
    );
}