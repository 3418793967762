import ApiService from '../services/api';
import ActionTemplate from './services/actionTemplate';

const GET_ADDRESS_SUGGESTS = 'GET_ADDRESS_SUGGESTS';
const GET_ADDRESS_DETAILS = 'GET_ADDRESS_DETAILS';
const UPDATE_ADDRESSES = 'UPDATE_ADDRESS';
const CREATE_ADDRESSES = 'CREATE_ADDRESS';
const DELETE_ADDRESSES = 'DELETE_ADDRESS';

/** Actions for Customer */
export default class AddressAction {

    constructor() {
        this.api = new ApiService();
        this.actionTemplate = new ActionTemplate();
    }
    
    getAddressSuggestions(query){
        return (dispatch) => {
            dispatch(this.actionTemplate.request(GET_ADDRESS_SUGGESTS));
            const url = '/address';
            return this.api.search(url, {q: query})
                .then((response) => dispatch(this.actionTemplate.success(GET_ADDRESS_SUGGESTS, response.data)))
                .catch((err) => {
                    /* istanbul ignore next */ 
                    dispatch(this.actionTemplate.failure(GET_ADDRESS_SUGGESTS, err));
                });
        };
    }

    getAddressDetails(propertyId) {
        return (dispatch) => {
            dispatch(this.actionTemplate.request(GET_ADDRESS_DETAILS));
            const url = '/address/' + propertyId;
            return this.api.get(url)
                .then((response) => dispatch(this.actionTemplate.success(GET_ADDRESS_DETAILS, response.data)))
                .catch((err) => {
                    /* istanbul ignore next */ 
                    dispatch(this.actionTemplate.failure(GET_ADDRESS_DETAILS, err));
                });
        };
    }

    updateAddresses(request) {
        return (dispatch) => {
            dispatch(this.actionTemplate.request(UPDATE_ADDRESSES));
            const url = '/customer/addresses';
            return this.api.post(url, request)
                .then((response) => {
                    if (response.data) 
                        dispatch(this.actionTemplate.success(UPDATE_ADDRESSES, response.data));
                    else 
                        /* istanbul ignore next */ 
                        dispatch(this.actionTemplate.failure(UPDATE_ADDRESSES, 'Failed to update addresses'));
                    
                })
                .catch((err) => {
                    /* istanbul ignore next */ 
                    dispatch(this.actionTemplate.failure(UPDATE_ADDRESSES, err));
                });
        };
    }

    createAddresses(request) {
        return (dispatch) => {
            dispatch(this.actionTemplate.request(CREATE_ADDRESSES));
            const url = '/customer/addresses';
            return this.api.post(url, request)
                .then((response) => {
                    if (response.data) 
                        dispatch(this.actionTemplate.success(CREATE_ADDRESSES, response.data));
                    else 
                        /* istanbul ignore next */ 
                        dispatch(this.actionTemplate.failure(CREATE_ADDRESSES, 'Failed to create addresses'));
                    
                })
                .catch((err) => {
                    /* istanbul ignore next */ 
                    dispatch(this.actionTemplate.failure(CREATE_ADDRESSES, err));
                });
        };
    }

    deleteAddresses(request) {
        return (dispatch) => {
            dispatch(this.actionTemplate.request(DELETE_ADDRESSES));
            const url = '/customer/addresses';
            return this.api.delete(url, request)
                .then((response) => {
                    if (response.data) 
                        dispatch(this.actionTemplate.success(DELETE_ADDRESSES, response.data));
                    else 
                        /* istanbul ignore next */ 
                        dispatch(this.actionTemplate.failure(DELETE_ADDRESSES, 'Failed to delete addresses'));
                    
                })
                .catch((err) => {
                    /* istanbul ignore next */ 
                    dispatch(this.actionTemplate.failure(DELETE_ADDRESSES, err));
                });
        };
    }
}   