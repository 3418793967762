import {
    GET_ADDRESS_SUGGESTS_REQUEST,
    GET_ADDRESS_SUGGESTS_SUCCESS,
    GET_ADDRESS_SUGGESTS_FAILURE,
    GET_ADDRESS_DETAILS_REQUEST,
    GET_ADDRESS_DETAILS_SUCCESS,
    GET_ADDRESS_DETAILS_FAILURE,
    UPDATE_ADDRESS_REQUEST,
    UPDATE_ADDRESS_SUCCESS,
    UPDATE_ADDRESS_FAILURE,
    CREATE_ADDRESS_REQUEST,
    CREATE_ADDRESS_SUCCESS,
    CREATE_ADDRESS_FAILURE,
    DELETE_ADDRESS_REQUEST,
    DELETE_ADDRESS_SUCCESS,
    DELETE_ADDRESS_FAILURE,
} from '../constants/reducer';

const initialPxPayState = {
    loader: false,
    addressSuggestions: null,
    detailsLoader: false,
    addressDetails: null,
    addressUpdateLoader: false,
    addressUpdateSuccess: true,
};
  
const address = (state = initialPxPayState, action) => {
  
    //This is to ensure immutability of state
    const newState = {...state};
  
    switch (action.type) {
    case GET_ADDRESS_SUGGESTS_REQUEST:
        newState.loader = true;
        newState.addressSuggestions = null;
        return newState;
    case GET_ADDRESS_SUGGESTS_SUCCESS:
        newState.loader = false;
        newState.addressSuggestions = action.data.suggestions;
        return newState;
    case GET_ADDRESS_SUGGESTS_FAILURE:
        newState.loader = false;
        newState.addressSuggestions = [];
        return newState;
    case GET_ADDRESS_DETAILS_REQUEST:
        newState.detailsLoader = true;
        newState.addressDetails = null; 
        return newState;
    case GET_ADDRESS_DETAILS_SUCCESS:
        newState.detailsLoader = false;
        newState.addressDetails = action.data; 
        return newState;
    case GET_ADDRESS_DETAILS_FAILURE:
        newState.detailsLoader = false;
        newState.addressDetails = null;
        return newState;
    case UPDATE_ADDRESS_REQUEST:
    case CREATE_ADDRESS_REQUEST:
    case DELETE_ADDRESS_REQUEST:
        newState.addressUpdateLoader = true;
        return newState;
    case UPDATE_ADDRESS_SUCCESS:
    case CREATE_ADDRESS_SUCCESS:
    case DELETE_ADDRESS_SUCCESS:
        newState.addressUpdateLoader = false;
        newState.addressUpdateSuccess = true;
        return newState;
    case UPDATE_ADDRESS_FAILURE:
    case CREATE_ADDRESS_FAILURE:
    case DELETE_ADDRESS_FAILURE:
        newState.addressUpdateLoader = false;
        newState.addressUpdateSuccess = false;
        return newState;
    default:
        return state;
    }
};
  
export default address;