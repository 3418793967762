import { makeStyles } from '@material-ui/core/styles';

const progressLoaderStyles = makeStyles(() => ({
    progressWrapper: (props) => props.progressWrapper ? props.progressWrapper : {
        margin: '0px',
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));

export default progressLoaderStyles;