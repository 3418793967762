import React from 'react';
import PropTypes from 'prop-types';

import AccountDetails from './accountDetails';

AccountAndContact.propTypes = {
    getAccountInfo: PropTypes.func.isRequired,
};

export default function AccountAndContact(props) {

    return (
        <div>
            <AccountDetails getAccountInfo={props.getAccountInfo}/>
        </div>
    );
}
