import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { LoginAction } from '../controller/actions';
import { pushToLandingPage } from '../controller/services/accountService';
import ProgressLoader from './components/reusables/ProgressLoader';

export default function UserValidationPage(){
    const dispatch = useDispatch();
    const query = useQuery();
    const loginUser = useSelector((state) => state.login);
    const history = useHistory();


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const userLogin = () => {
        const codeParam = query.get('code') ? query.get('code') : null;
        if (codeParam) 
            dispatch(new LoginAction().userVerification(codeParam));
        
    };

    useEffect(() => {
        if (loginUser.claudId) 
            dispatch(new LoginAction().getUmProfile());
        
    // eslint-disable-next-line
     }, [loginUser.claudId]);

    useEffect(() => {
        if (loginUser.profile)
            pushToLandingPage(loginUser, history);
    }, [loginUser.profile]);

    useEffect(() => {
        userLogin();
    // eslint-disable-next-line
    }, []);

    return (
        <div className="body-wrapper">
            <ProgressLoader text="Validating..." />
        </div>
    );
}