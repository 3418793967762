const formatBusinessNumber = (businessNumber) => {
    if (businessNumber) {
        if (businessNumber.length === 9) 
            return businessNumber.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
        else if (businessNumber.length === 11) 
            return businessNumber.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
    } else 
        return 'N/A';
};

const formatCardNumber = (cardNumber) => {
    try {
        if (!cardNumber || cardNumber?.length < 2)
            return 'XXXX XXXX XXXX XXXX';
        // Replace all the '.' with 'X'
        cardNumber = cardNumber.replaceAll('.', 'X');
        // Remove all white spaces
        cardNumber = cardNumber.trim();
        // Split the numbers in group of 4 characters Eg: 1234 5678 
        cardNumber = cardNumber.match(/.{1,4}/g);
        // Convert the resulting array to string
        cardNumber = cardNumber.join(' ');
    } catch (error) {
        console.error(error);
    }
    return cardNumber;
};

const formatAccountNumber = (accountNumber) => {
    try {
        // Replace all the '.' with 'X'
        accountNumber = accountNumber.replaceAll('.', 'X');
        // Remove all white spaces
        accountNumber = accountNumber.trim();
        // Convert the resulting array to string
        accountNumber = accountNumber.join(' ');
    } catch (error) {
        console.error(error);
    }
    return accountNumber;
};

const hasNoValidationErrors = (validator) => !Object.keys(validator).some((key) => validator[key].error);
    
const validateEmail = (email) => {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
};

const isValidPhoneNumber = (areaCode = '', phoneNumber = '') => {
    // Min = 1, Max = 5
    const isAreaCodeValid = Boolean(
        areaCode.match(/^[0-9]{0,5}$/),
    );
    
    // Min = 5, Max = 12
    const isPhoneNumberValid = Boolean(
        phoneNumber.replace(/\s/g, '')
            .match(/^[0-9 ]{5,12}$/),
    );

    return (isAreaCodeValid && isPhoneNumberValid);
};

const validateText = (type, value) => {
    if (!value) 
        return false;

    let isValid = true;
    switch (type) {
    case 'emailAddress':
    case 'primaryContactEmailAddress':
    case 'paymentContactEmailAddress':
    case 'organizationDEOXAccountEmailC':
        isValid = validateEmail(value);
        break;
    case 'mobileNumber':
        isValid = Boolean(value.replace(/\s/g, '').match(/^[0-9 ]{10}$/));
        break;
    case 'phoneNumber':
    case 'workPhoneNumber':
        // Min = 5, Max = 12
        isValid = Boolean(value.replace(/\s/g, '').match(/^[0-9 ]{5,12}$/));
        break;
    case 'phoneAreaCode':
    case 'workPhoneAreaCode':
        // Min = 1, Max = 5
        isValid = Boolean(value.match(/^[0-9]{0,5}$/));
        break;
    case 'singleLineAddress':
    case 'addressLine':
        isValid = Boolean(value.match(/^[a-z0-9- ]+$/i));
        break;
    case 'city':
        isValid = Boolean(value.match(/^[a-z -]+$/i));
        break;
    case 'postalCode':
        isValid = Boolean(value.match(/^[0-9]{1,4}$/));
        break;
    case 'firstName':
    case 'lastName':
        isValid = Boolean(value.match(/^[a-z ]{2,}$/i));
        break;
    default:
        break;
    }
    return isValid;
};

const objectIsEmpty = (object) => !object || Object.keys(object).length === 0;

const sanitizeAddressType = (addressType) => {
    try {
        if (addressType) {
            //Remove empty spaces if any
            addressType = addressType.replace(/\s/g, '');
    
            //Split the string by commas (If there are no commas it will create an array with one element Eg: ['BILL_TO'])
            addressType = addressType.split(',');
    
            //Remove empty strings from the Array Eg: ['', 'BILL_TO'] will be converted to ['BILL_TO']
            addressType = addressType.filter(Boolean);
    
            //Convert Array to String Eg: ['BILL_TO', 'SELL_TO'] will get converted to "BILL_TO, SELL_TO"
            addressType = addressType.toString();
        }
    } catch (error) {
        console.error('Address sanitization error:', error);
    }
    return addressType;
};

const replaceSpaces = (value) => value ? value.replace(/\s/g, '') : '';

export {
    formatBusinessNumber,
    formatCardNumber,
    formatAccountNumber,
    hasNoValidationErrors,
    validateEmail,
    validateText,
    isValidPhoneNumber,
    objectIsEmpty,
    sanitizeAddressType,
    replaceSpaces,
};