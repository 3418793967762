import axios from 'axios';
import { store } from '../../store';
import { LoginAction } from './../actions';

const CancelToken = axios.CancelToken;
let cancel;

export default class Api {
    constructor() {
        this.API_ROOT = '/api';
    }

    getFullUrl(fragment) {
        return this.API_ROOT + fragment;
    }

    search(url, params) {
        cancel && cancel();
        return axios.get(this.getFullUrl(url), {
            params,
            cancelToken: new CancelToken(function executor(c){
                cancel = c;
            }),
        }).catch((error) => {
            if (error.response && error.response.status === 401) 
                store.dispatch(new LoginAction().redirectToLoginPage());
            
            return error;
        });
    }

    get(url, params) {
        return axios.get(this.getFullUrl(url), {
            params,
        }).catch((error) => {
            if (error.response && error.response.status === 401) 
                store.dispatch(new LoginAction().redirectToLoginPage());
            
            return error;
        });
    }

    post(url, params) {
        return axios.post(this.getFullUrl(url), params)
            .catch((error) => {
                if (error.response && error.response.status === 401) 
                    store.dispatch(new LoginAction().redirectToLoginPage());
                
                return error;
            });
    }

    patch(url, params) {
        return axios.patch(this.getFullUrl(url), params)
            .catch((error) => {
                if (error.response && error.response.status === 401) 
                    store.dispatch(new LoginAction().redirectToLoginPage());
                
                return error;
            });
    }

    delete(url, params) {
        return axios.delete(this.getFullUrl(url), 
            {
                data: params,
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) 
                    store.dispatch(new LoginAction().redirectToLoginPage());
                
                return error;
            });
    }

}
