import gql from 'graphql-tag';
import { formatCardNumber, formatAccountNumber, objectIsEmpty } from './helper';

const ALL_PAYMENT_CODES = ['American Express', 'Diners', 'Mastercard Credit', 'Mastercard Debit', 'Visa Credit', 'Visa Debit', 'Direct Debit - Bank'];
const CARD_TYPE_CODES = ['American Express', 'Diners', 'Mastercard Credit', 'Mastercard Debit', 'Visa Credit', 'Visa Debit'];
const CREATE_ACTIVITY = gql`
  mutation CreateActivity($input: CreateActivityRequest) {
    activity: createActivity(input_: $input){
        subject
        activityFunctionCode
        accountNumber
        statusCode
        activityPartialDescription
        activityNumber
    } 
  }
`;


const hasNoDefaultPaymentMethod = (paymentMethod = {}) => 
    !objectIsEmpty(paymentMethod) && 
    (
        !paymentMethod.defaultPaymentMethod ||
        (
            paymentMethod.defaultPaymentMethod && 
            (
                paymentMethod.status !== 'OP' || !ALL_PAYMENT_CODES.includes(paymentMethod.paymentMethodCode)
            )
        )
    );

const hasDirectDebitPaymentMethod = (paymentMethod = {}) => 
    paymentMethod.defaultPaymentMethod &&
    paymentMethod.status === 'OP' &&
    paymentMethod.paymentMethodCode === 'Direct Debit - Bank'
;

const hasCreditCardPaymentMethod = (paymentMethod = {}) => 
    paymentMethod.defaultPaymentMethod &&
    paymentMethod.status === 'OP' &&
    CARD_TYPE_CODES.includes(paymentMethod.paymentMethodCode)
;

const getPaymentUpdateActivityDesc = (username, clickAccountNumber, paymentMethodDetails) => {
    if (hasCreditCardPaymentMethod(paymentMethodDetails)) {
        const {
            accountName,
            expiryDate,
            accountNumber,
            paymentMethodCode,
        } = paymentMethodDetails || {};
        return `Self Service Portal Update Made By ${username} on ${new Date()} to Account Number: ${clickAccountNumber}, Card Type: ${paymentMethodCode}, Card Number: ${formatCardNumber(accountNumber)}, Expiry Date: ${expiryDate}, Card Holder Name: ${accountName}`;
    } else if (hasDirectDebitPaymentMethod(paymentMethodDetails)) {
        const {
            accountName,
            accountNumber,
            bsb,
        } = paymentMethodDetails || {};
        return `Self Service Portal Update Made By ${username} on ${new Date()} to Account Number: ${clickAccountNumber}, Payment Method: Direct Debit, Bank Account Number: ${bsb} - ${formatAccountNumber(accountNumber)}, 'Account Holder Name: ${accountName}`;
    }
};

export {
    hasNoDefaultPaymentMethod,
    hasDirectDebitPaymentMethod,
    hasCreditCardPaymentMethod,
    getPaymentUpdateActivityDesc,
    CREATE_ACTIVITY,
};