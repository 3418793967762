import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { 
    CardHeader, 
    Card, 
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Box,
} from '@material-ui/core';
import Receipt from '@material-ui/icons/Receipt';
import Skeleton from '@material-ui/lab/Skeleton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GetAppIcon from '@material-ui/icons/GetApp';
import { format, parse } from 'date-fns';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(() => ({
    card: {
        textAlign: 'left',
    },
    cardHeader: {
        backgroundColor: '#eee',
    },
    disableTextTranform: {
        textTransform: 'none !important',
    },
    tableHeader: {
        textTransform: 'none !important',
        fontWeight: 'bold',
    },
    date: {
        textTransform: 'capitalize',
    },
    fullWidthButton: {
        width: '100% !important',
    },
    spinner: {
        marginLeft: '10px',
    },
}));

const DEFAULT_NUMBER_OF_INVOICES = 5;
const MAX_NUMBER_OF_INVOICES_TO_SHOW = 15;

/**
 * Invoices Component.
 * Displays the list of Invoices which provides an option to download an individual invoice.
 *
 * @param       {Array}        invoices             An array of invoices which will be used to display in the table.
 * @param       {Function}     downloadInvoice      Function used to download the invoice.
 * @returns     {JSX.Element}                       The component markup.
 */
function Invoices({downloadInvoice, downloadStartedFor}) {
    const customer = useSelector((state) => state.customer);
    const classes = useStyles();
    const theme = useTheme();
    const smallDevice = useMediaQuery(theme.breakpoints.down('xs'));
    const [showMore, setShowMore] = useState(false);
    const [invoicesSubset, setInvoicesSubset] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const loading = customer?.invoicesLoader;

    // Returns a subset of invoices. Defaults to DEFAULT_NUMBER_OF_INVOICES
    const sortAndSetInvoicesSubset = (numberOfInvoicesToShow = DEFAULT_NUMBER_OF_INVOICES) => {
        const sortedInvoices = invoices.sort((firstInvoice, secondInvoice) => secondInvoice.billDate.localeCompare(firstInvoice.billDate));
        const subsetOfInvoices = sortedInvoices.slice(0, numberOfInvoicesToShow);
        setInvoicesSubset(subsetOfInvoices);
    };

    // Hook called when ShowMore button is clicked
    useEffect(() => {
        sortAndSetInvoicesSubset(MAX_NUMBER_OF_INVOICES_TO_SHOW);
    }, [showMore]);

    // Hook called when new set of Invoices are recieved from the API
    useEffect(() => {
        if (customer.invoices?.invoices)
            setInvoices(customer.invoices?.invoices);
    }, [customer.invoices?.invoices]);

    // Hook called when invoices state is set
    useEffect(() => {
        sortAndSetInvoicesSubset();
    }, [invoices]);
    
    const renderInvoiceList = () => {
        return (
            <TableContainer>
                {(invoices.length > 0 || loading) &&
                    <Table aria-label="invoices">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.tableHeader}>Date</TableCell>
                                <TableCell align="left" className={classes.tableHeader}>Invoice Number</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { loading && 
                                <TableRow>
                                    <TableCell><Skeleton variant="text" /></TableCell>
                                    <TableCell><Skeleton variant="text" /></TableCell>
                                    <TableCell><Skeleton variant="text" /></TableCell>
                                </TableRow>
                            }
                            {!loading && invoicesSubset?.map((invoice) => (
                                <TableRow key={invoice.invoiceId}>
                                    <TableCell className={classes.date}>
                                        {   
                                            format(parse(invoice.billDate, "yyyy-MM-dd'T'HH:mm:ss", new Date()), 'dd MMM yyyy')                                   
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {invoice.billNumber}
                                    </TableCell>
                                    <TableCell align="right">
                                        {!smallDevice && (
                                            <Button variant="contained" color="primary" disabled={downloadStartedFor === invoice.invoiceId} onClick={() => downloadInvoice(invoice.invoiceId, invoice.billNumber)}>
                                                Download {downloadStartedFor === invoice.invoiceId && <CircularProgress size={20} className={classes.spinner}/>}
                                            </Button>
                                        )}
                                        {smallDevice && (
                                            <Box mt={1}>
                                                {downloadStartedFor !== invoice.invoiceId && <GetAppIcon size={40} color="primary" disabled={downloadStartedFor === invoice.invoiceId} onClick={() => downloadInvoice(invoice.invoiceId, invoice.billNumber)}/>}
                                                {downloadStartedFor === invoice.invoiceId && <CircularProgress size={20} className={classes.spinner}/>}
                                            </Box>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                }
                {   
                    invoices.length > DEFAULT_NUMBER_OF_INVOICES && 
                    invoices.length > invoicesSubset.length &&
                    invoicesSubset.length < MAX_NUMBER_OF_INVOICES_TO_SHOW &&
                    <Box justifyContent="center" alignItems="center" display="flex" mt={2}>
                        <Button size="small" variant="text" color="primary" onClick={() => setShowMore(true)}>
                            Show more
                        </Button>
                    </Box>  
                }

                {
                    invoicesSubset.length > DEFAULT_NUMBER_OF_INVOICES &&
                    invoices.length > 0 &&
                    <React.Fragment>
                        <Box justifyContent="center" alignItems="center" display="flex" mt={1} className={classes.disableTextTranform}>
                            <p>Please note a maximum of 15 invoices is shown at this time.</p>
                        </Box>  
                        <Box justifyContent="center" alignItems="center" display="flex" className={classes.disableTextTranform}>
                            <p>To request older invoices, please contact our <a href="https://corelogic.com.au/contact-us" target="_blank" rel="noreferrer">customer care</a> team.</p>
                        </Box>  
                    </React.Fragment>
                }
                {invoices.length === 0 && !loading &&
                    <Box justifyContent="center" alignItems="center" display="flex" mt={2} className={classes.disableTextTranform}>
                        No invoices found.
                    </Box>  
                }
            </TableContainer>
        );
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                className={classes.cardHeader}
                title={
                    <span className={`card-header-title ${classes.disableTextTranform}`}>
                        <Receipt style={{ margin: '-4px 5px -4px 0px', color: '#38424A' }}/>
                        Manage your invoices
                    </span>
                }
            />
            <CardContent>
                {renderInvoiceList()}
            </CardContent>
        </Card>
    );
}

Invoices.propTypes = {
    downloadInvoice: PropTypes.func.isRequired,
    downloadStartedFor: PropTypes.bool.isRequired,
};

export default Invoices;