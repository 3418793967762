import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    USER_LOGOUT_REQUEST,
    USER_LOGOUT_SUCCESS,
    USER_LOGOUT_FAILURE,
    USER_EXPIRY_SESSION,
    UM_PROFILE_REQUEST,
    UM_PROFILE_SUCCESS,
    UM_PROFILE_FAILURE,
    USER_VERIFCATION_REQUEST,
    USER_VERIFCATION_SUCCESS,
    USER_VERIFCATION_FAILURE,
    USER_SESSION_REQUEST,
    USER_SESSION_SUCCESS,
    USER_SESSION_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    SET_ACCOUNT_SUCCESS,
} from '../constants/reducer';

const initialUserLoginState = {
    profile: null,
    loader: false,
    profileLoader: false,
    verificationLoader: false,
    claudId: null,
};

const login = (state = initialUserLoginState, action) => {

    //This is to ensure immutability of state
    let newState = {...state};

    switch (action.type) {

    case LOGIN_REQUEST:
        newState.loader = true;
        newState.claudId = null;
        return newState;
    case LOGIN_SUCCESS:
        if (action.data) 
            window.location.assign(action.data);
        else 
            window.location.assign('/self-service-na');
        
        newState.loader = false;
        return newState;
    case LOGIN_FAILURE:
        newState.loader = false;
        return newState;
    case USER_VERIFCATION_REQUEST:
        newState.verificationLoader = true;
        newState.claudId = null;
        return newState;
    case USER_VERIFCATION_SUCCESS:
        newState.verificationLoader = false;
        if (action.data.response && 
          action.data.response.data.errors && 
          action.data.response.data.errors[0].msg === 'Code provided has expired or invalid') {
            newState.profile = null;
            newState.isUserLoggedIn = false;
        } else {
            newState.claudId = action.data;
            newState.isUserLoggedIn = true;
        }
        return newState;
    case USER_VERIFCATION_FAILURE:
        newState.verificationLoader = false;
        return newState;
    case USER_LOGOUT_REQUEST:
        return newState;
    case USER_LOGOUT_SUCCESS:
        newState = initialUserLoginState;
        window.location.assign(action.data);
        return initialUserLoginState;
    case USER_LOGOUT_FAILURE:
        return newState;
    case USER_EXPIRY_SESSION:
        newState = initialUserLoginState;
        return newState;
    case UM_PROFILE_REQUEST:
        newState.profile = null;
        newState.profileLoader = true;
        return newState;
    case UM_PROFILE_SUCCESS:
        newState.profile = action.data;
        newState.profileLoader = false;
        return newState;
    case UM_PROFILE_FAILURE:
        newState.profileLoader = false;
        return newState;
    case USER_SESSION_REQUEST:
        newState.loader = true;
        return newState;
    case USER_SESSION_SUCCESS:
        newState.isUserLoggedIn = action.body;
        newState.loader = false;
        return newState;
    case USER_SESSION_FAILURE:
        newState.loader = false;
        return newState;
    case CHANGE_PASSWORD_REQUEST:
        return newState;
    case CHANGE_PASSWORD_SUCCESS:
        if (action.data) 
            window.location.assign(action.data);
        
        return newState;
    case CHANGE_PASSWORD_FAILURE:
        return newState;
    case SET_ACCOUNT_SUCCESS:
        newState.profile['accountNumber'] = action.data.accountNumber;
        newState.profile['accountGuid'] = action.data.accountGuid;
        if (window.location.pathname !== '/portal/account-and-contact')
            window.location.assign('/portal/account-and-contact');
        return newState;
    default:
        return state;
    }

};
export default login;