import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';
import AccountAndContact from './components/customer/accountNContact/accountAndContact';
import PaymentsAndInvoices from './components/customer/paymentNSubscription/paymentAndSubscription';
import { CustomerAction, LoginAction } from '../controller/actions';
import { makeStyles } from '@material-ui/core/styles';
import { SegmentAnalyticsService } from '../controller/services';
import { selectedAccount, userHasSingleAccount } from 'controller/services/accountService';
import CorelogicLogoBrand from '../image/CorelogicLogoBrand.svg';
import CorelogicLogoBrandMobile from '../image/CorelogicLogoBrandMobile.svg';
import CorelogicLogoLine from '../image/Line.svg';
import ProgressLoader from './components/reusables/ProgressLoader';

const HOVER_BACKGROUND_COLOR = '#E0DFDE';
const ENABLED_TAB_COLOR = '#38424A';


const useStyles = makeStyles((theme) => ({
    tabPanel: {
        padding: 0,
        textTransform: 'lowercase',
    },
    tabPaper: {
        boxShadow: 'none !important',
    },
    tabOption: {
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '16px',
    },
    accountTab: {
        margin: '5px 1px 5px 5px',
        '&:hover': {
            backgroundColor: HOVER_BACKGROUND_COLOR,
        },
    },
    paymentTab: {
        margin: '5px 5px 5px 1px',
        '&:hover': {
            backgroundColor: HOVER_BACKGROUND_COLOR,
        },
    },
    enabledTab: {
        color: ENABLED_TAB_COLOR,
    },
    learnMoreHeading: {
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '17px',
        lineHeight: '24px',
    },
    learnMoreParagraph: {
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '17px',
        lineHeight: '24px',
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
        padding: '0',
    },
    logoBrand: {
        [theme.breakpoints.down('xs')]: {
            backgroundImage: `url(${CorelogicLogoBrandMobile})`,
            backgroundSize: '100px',
            width: '95px !important',
            height: '80px !important',
        },
        [theme.breakpoints.down('sm')]: {
            backgroundImage: `url(${CorelogicLogoBrandMobile})`,
            width: '150px',
            height: '125px',
        },
        [theme.breakpoints.up('md')]: {
            backgroundImage: `url(${CorelogicLogoBrand})`,
        },
        [theme.breakpoints.up('lg')]: {
            backgroundImage: `url(${CorelogicLogoBrand})`,
        },
        width: '350px',
        height: '165px',
        backgroundRepeat: 'no-repeat',
    },
    logoLine: {
        [theme.breakpoints.down('xs')]: {
            margin: '.5rem !important',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '1rem',
        },
        margin: '2rem',
    },
    logoText: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '24px !important',
            lineHeight: '54px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '36px',
            lineHeight: '54px',
        },
        fontFamily: 'Source Sans Pro',
        fontWeight: '600',
        fontSize: '45px',
        lineHeight: '42px',
    },
    paddedContainer: {
        maxWidth: '1250px',
        display: 'inline-block',
        padding: '120px 4% 0',
        width: '100%',
    },
    pageContainer: {
        'min-height': 'calc(100vh - 245px)',
    },
}));

export default function CustomerAdminPortalPage(){
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = useSelector((state) => state.login);
    const customer = useSelector((state) => state.customer);
    const [tabValue, setTabValue] = useState('account-and-contact');
    const [profile, setProfile] = useState(user.profile);

    const { myProfile } = useParams();
    const history = useHistory();

    const handleChange = (event, newValue) => {
        switch (newValue) {
        case 'payment-and-invoices':
            history.replace('/portal/payment-and-invoices');
            setTabValue(newValue);
            break;
        default:
            history.replace('/portal/account-and-contact');
            getAccountInfo();
            setTabValue(newValue);
            break;
        }
    };

    const getAccountInfo = () => {
        if (profile?.userDetails)
            dispatch(new CustomerAction().getAccountAndContact());

    };

    const handleLinkClick = () => {
        !new SegmentAnalyticsService().trackAction('Learn More', user, customer.customer.account);
    };


    useEffect(() => {
        switch (myProfile) {
        case 'payment-and-invoices':
            setTabValue('payment-and-invoices');
            !new SegmentAnalyticsService().analyticsPageView('Payment and Subscription', user);
            break;
        default:
            setTabValue('account-and-contact');
            getAccountInfo();
            !new SegmentAnalyticsService().analyticsPageView('Account and Contact', user);
            break;
        }
        // eslint-disable-next-line
    }, [myProfile, profile?.accountNumber]);

    useEffect(() => {
        if (!profile) dispatch(new LoginAction().redirectToLoginPage());
    }, [profile]);

    useEffect(() => {
        if (userHasSingleAccount(user))
            selectedAccount(user.profile.accounts[0]?.accountDetails);
    }, [user.profile?.accounts]);

    useEffect(() => {
        if (user.profile) setProfile(user.profile);
    }, [user.profile]);

    let classesForAccountTab = `${classes.tabOption} ${classes.accountTab} `;
    let classesForPaymentTab = `${classes.tabOption} ${classes.paymentTab} `;
    classesForPaymentTab += classes.enabledTab;
    classesForAccountTab += classes.enabledTab;

    return (
        <div className={classes.pageContainer}>
            {
                profile?.userDetails &&
                <TabContext value={tabValue}>
                    <div className={classes.paddedContainer}>
                        <Container className={classes.logoContainer}>
                            <div className={classes.logoBrand}>

                            </div>
                            <img
                                alt="Line"
                                className={classes.logoLine}
                                src={CorelogicLogoLine}
                            />
                            <h1 className={classes.logoText}>RP Data Account Portal</h1>
                        </Container>
                        <h1>Welcome, {profile.userDetails.firstName} {profile.userDetails.lastName}</h1>
                        <h4>
                            Manage your organisation account details to make CoreLogic work better for you. 
                            <a href="https://auhelpcentre.corelogic.com.au/hc/en-au/sections/4404892320015-Self-Service-Portal" target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}> Learn More</a>
                        </h4>
                        <Paper square className={classes.tabPaper}>
                            <Tabs
                                value={tabValue}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="customer details"
                                style={{marginBottom: '30px', backgroundColor: '#eee'}}
                            >
                                <Tab label={
                                    <span className="tab-label" style={{textTransform: 'none'}}>Account and Contact</span>
                                } value="account-and-contact" className={classesForAccountTab} />
                                <Tab label={
                                    <span className="tab-label">Payments and Invoices</span>
                                } value="payment-and-invoices" className={classesForPaymentTab} />
                            </Tabs>
                        </Paper>
                        <TabPanel value="account-and-contact" className={classes.tabPanel}>
                            <AccountAndContact
                                account={customer.customer?.account}
                                contact={customer.customer?.contact}
                                getAccountInfo={getAccountInfo}
                            />
                        </TabPanel>
                        <TabPanel value="payment-and-invoices" className={classes.tabPanel}>
                            <PaymentsAndInvoices />
                        </TabPanel>
                    </div>
                </TabContext>
            }
            {
                !profile && 
                <div className="body-wrapper">
                    <ProgressLoader text="Redirecting to login page..." />
                </div>
            }
        </div>
    );
}
