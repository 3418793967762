import React from 'react';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';

//IMAGES
import CorelogicLogo from '../../../image/CorelogicLogo.svg';

export default function Footer() {

    function redirectToURL(link) {
        window.open(link, '_blank', 'noopener noreferrer');
    }

    return (
        <footer className="footer">
            <CssBaseline />
            <Box>
                <img src={CorelogicLogo} alt="CoreLogic" className="footer-logo" onClick={() => {
                    redirectToURL('https://www.corelogic.com.au/corestore')
                    ;
                }}/>
                <div>
                    <p className="no-margin small-text">
                        &copy; RP Data Pty Ltd t/a CoreLogic Asia Pacific (ABN 67 087 759 171) {new Date().getFullYear()}. All rights reserved.
                    </p>
                    <p className="no-margin small-text">
                        <a 
                            className="white-link" 
                            id="privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.corelogic.com.au/about-us/privacy-policy"
                        >
                          Privacy Policy
                        </a> |
                        <a 
                            className="white-link" 
                            id="general-terms"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.corelogic.com.au/about-us/terms-and-conditions"
                        >
                          General Terms & Conditions
                        </a> |
                        <a 
                            className="white-link"
                            id="website-terms" 
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.corelogic.com.au/about-us/website-terms"
                        >
                          Website Terms of Use
                        </a> |
                        <a 
                            className="white-link"
                            id="data-code-of-conduct" 
                            target="_blank"
                            rel="noopener noreferrer"
                            href="http://www.propertydatacodeofconduct.com.au/ttsvr/n/qvas-195"
                        >
                          Data Code of Conduct
                        </a> |
                        <a 
                            className="white-link"
                            id="disclaimer" 
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.corelogic.com.au/general/copyright_and_disclaimer_notices"
                        >
                          Disclaimer
                        </a>
                    </p>
                </div>
            </Box>
        </footer>
      
    );
}
