const DATA_SITE = 'my.corelogic';

const standardProperties = (user, account, pageName) => {
    const name = pageName === 'Select Account' ? 'Select Account' : 'Account and Contact';
    return {
        'Page': pageName,
        'name': name,
        'Email': account ? account.organizationDEOXAccountEmailC : 'N/A',
        'site': DATA_SITE,
        'Site Version': 'N/A',
        '$user_id': user.profile?.userDetails.username,
        'User name': user.profile?.userDetails.username,
        'First Name': user.profile?.userDetails.firstname,
        'Last Name': user.profile?.userDetails.lastname,
        'Franchise ID': 'N/A',
        'CRM Account ID': user.profile?.crmAccountGuid,
        'Industry Segment': 'N/A',
        'Trade Name': account ? account.organizationDEOXTradingNameC : 'N/A',
    };
};
export default class SegmentAnalytics {

    getPageName = () => {
        const pathname = window.location.href;
        if (pathname.indexOf('/portal/') > 0)  
            return 'Costumer Admin Portal';
        
        if (pathname.indexOf('/email_validation') > 0) 
            return 'User Validation';
        
        if (pathname.indexOf('/register') > 0) 
            return 'Sign Up';
        
        if (pathname.indexOf('/self-service-na') > 0) 
            return 'Self Service NA';
        
        if (pathname.indexOf('/private/status') > 0) 
            return 'Status';

        if (pathname.indexOf('/accounts') > 0) 
            return 'Select Account';
        
        return 'Account and Contact';
    }

    trackGenericPageInfo = (eventMessage) => {
        const trackingObj = {
            eventType: eventMessage,
            data: {
                'Page': this.getPageName(),
            },
        };
        this.analyticsTrack(trackingObj);
    }

    // Login / Logout
    trackUserSession = (eventMessage, user, account) => {
        const trackingObj = {
            eventType: eventMessage === 'Log In' ? 'Log In' : 'Logged Out',
            data: standardProperties(user, account, this.getPageName()),
        };
        this.analyticsTrack(trackingObj);
    }

    //Track if email was validated
    trackEmailValidation = (validated) => {
        const trackingObj = {
            eventType: 'Email validation',
            data: {
                'Page': this.getPageName(),
                'Validated': validated ? 'Yes' : 'No',
            },
        };
        this.analyticsTrack(trackingObj);
    }

    trackAction = (eventMessage, user, account) => {
        const trackingObj = {
            eventType: eventMessage,
            data: standardProperties(user, account, this.getPageName()),
        };
        this.analyticsTrack(trackingObj);
    }

    trackInvoiceDownload = (user, account, invoiceNumber) => {
        const mixpanelData = standardProperties(user, account, this.getPageName());
        mixpanelData['Invoice Number'] = invoiceNumber;
        const trackingObj = {
            eventType: 'Download Invoice',
            data: mixpanelData,
        };
        this.analyticsTrack(trackingObj);
    }

    trackField = (eventMessage, fieldName) => {
        const trackingObj = {
            eventType: eventMessage,
            data: {
                'Page': this.getPageName(),
                'name': fieldName ? 'Account and Contact' : 'Self service na',
                'site': DATA_SITE,
                'CTA': fieldName ? `Want to change ${fieldName}` : 'User not applicable for self service portal',
            },
        };
        this.analyticsTrack(trackingObj);
    }

    trackError = (action, pageName, errorMessage, errorCode) => {
        const trackingObj = {
            eventType: 'Capture Error',
            data: {
                'site': DATA_SITE,
                'Page': pageName ? pageName : this.getPageName(),
                'Action': action,
                'Error Message': errorMessage,
                'Error Code': errorCode,
            },
        };
        this.analyticsTrack(trackingObj);
    }

    //ACCOUNT AND CONTACT
    trackAccountAndContact = (eventMessage, section, result) => {
        const trackingObj = {
            eventType: eventMessage,
            data: {
                'name': 'Account and Contact',
                'Section': section,
                'Result': result ? result : '',
            },
        };
        this.analyticsTrack(trackingObj);
    }

    // CHANGE PAYMENT METHOD
    trackChangePaymentMethod = (user, account, cta, paymentMethodType) => {
        const mixpanelData = standardProperties(user, account, this.getPageName());
        mixpanelData['CTA'] = cta; //"Add New Credit Card" or "Change to Credit Card"
        mixpanelData['name'] = 'Payments and Invoices';
        if (paymentMethodType) mixpanelData['Payment Method Type'] = paymentMethodType;
        const trackingObj = {
            eventType: 'Change Payment Method',
            data: mixpanelData,
        };
        this.analyticsTrack(trackingObj);
    }

    trackSuccessfulChangePaymentMethod = (user, account, cta) => {
        const mixpanelData = standardProperties(user, account, this.getPageName());
        mixpanelData['CTA'] = cta; //"Add New Credit Card" or "Change to Credit Card"
        mixpanelData['name'] = 'Payments and Invoices';
        const trackingObj = {
            eventType: 'Successful Change Payment Method',
            data: mixpanelData,
        };
        this.analyticsTrack(trackingObj);
    }

    trackFailedChangePaymentMethod = (user, account, cta) => {
        const mixpanelData = standardProperties(user, account, this.getPageName());
        mixpanelData['CTA'] = cta; //"Add New Credit Card" or "Change to Credit Card"
        mixpanelData['name'] = 'Payments and Invoices';
        const trackingObj = {
            eventType: 'Failed Change Payment Method',
            data: mixpanelData,
        };
        this.analyticsTrack(trackingObj);
    }

    //PAGE
    analyticsPageView = (page, user, account) => {
        window.analytics.page(page, standardProperties(user, account, this.getPageName()));
    }

    // TRACK
    analyticsTrack = (trackingObj) => {
        trackingObj.data.site = DATA_SITE,
        window.analytics.track(trackingObj.eventType, trackingObj.data);
    }

    analyticsAlias = (claudId) => {
        window.analytics.alias(claudId, { previousId: window.mixpanel.get_distinct_id(), userId: claudId});
    }

    analyticsIdentity = (user) => {
        if (user)
            window.analytics.identify(user.profile?.crmAccountGuid, {
                Email: user.profile?.userDetails?.email || 'N/A',
                firstName: user.profile?.userDetails?.firstName,
                lastName: user.profile?.userDetails?.lastName,
                username: user.profile?.userDetails?.username,
                site: DATA_SITE,
            });
        
    }

    analyticsLogoutUser = () => {
        window.analytics?.reset();
        window.mixpanel?.reset();
    }

    trackUserActivity = (loginUser) => {
        if (loginUser.isUserLoggedIn) this.trackUserSession('Log In', loginUser);
        this.analyticsIdentity(loginUser);
    }

    //Track when a page is loaded
    trackLoadPage = () => {
        const pageName = this.getPageName();
        const trackingObj = {
            eventType: 'Loaded a Page',
            data: {
                'Page': pageName,
                'name': pageName,
            },
        };
        this.analyticsTrack(trackingObj);
    }
}
