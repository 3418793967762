import React, { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';

import PaymentDetails from './paymentDetails';
import Invoices from './invoices';
import {useDispatch, useSelector} from 'react-redux';
import {CustomerAction} from '../../../../controller/actions';
import Alert from '../../general/alert';
import { SegmentAnalyticsService } from '../../../../controller/services';

/**
 * PaymentsAndInvoices Component.
 * Contains the Payment details and Invoices component.
 *
 * @returns     {JSX.Element}                               The component markup.
 */
export default function PaymentsAndInvoices() {
    const user = useSelector((state) => state.login);
    const dispatch = useDispatch();
    const [showInvoiceDownloadAlert, setShowInvoiceDownloadAlert] = useState(false);
    const [downloadStartedFor, setDownloadStartedFor] = useState(null);
    const customer = useSelector((state) => state.customer);

    const getPaymentDetails = () => {
        const payload = {
            region: 'AU',
        };
        dispatch(new CustomerAction().getPaymentMethod(user.profile.accountNumber, payload));
    };

    const getInvoices = () => {
        dispatch(new CustomerAction().getInvoices(user.profile.accountNumber,
            user.profile.crmAccountGuid));
    };

    // Hook runs when the component is mounted.
    useEffect(() => {
        //Dispatch the action to fetch the Payment details and Invoices
        getPaymentDetails();
        getInvoices();
    }, []);

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') 
            return;
        setShowInvoiceDownloadAlert(false);
    };

    // Downloads the base64 encoded invoice
    const downloadInvoice = (response, billNumber) => {
        let base64String = '';
        const link = document.createElement('a');

        if (response?.fileType === 'PDF') 
            base64String = `data:application/pdf;base64, ${response.content}`;
        
        
        link.download = `Invoice_${billNumber}`;
        link.href = base64String;
        link.click();
    };


    // Fetches the Invoice to be downloaded from the API.
    const onDownloadInvoice = (invoiceId, billNumber) => {
        const accountNumber = user.profile.accountNumber;
        setDownloadStartedFor(invoiceId);
        new CustomerAction().getInvoice(accountNumber, invoiceId)
            .then((response) => {
                if (response?.data?.content?.length > 0) {
                    !new SegmentAnalyticsService().trackInvoiceDownload(user, customer.customer.account, billNumber);
                    downloadInvoice(response.data, billNumber);
                } else  
                    setShowInvoiceDownloadAlert(true);
                
            })
            .catch((error) => {
                console.log('Error:', error);
                setShowInvoiceDownloadAlert(true);
            })
            .finally(() => setDownloadStartedFor(null));
    };

    return (
        <React.Fragment>
            <PaymentDetails />
            <Invoices 
                downloadInvoice={onDownloadInvoice}
                downloadStartedFor={downloadStartedFor}
            />
            <Snackbar className="alert-toast" open={showInvoiceDownloadAlert} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="error">
                    Sorry, the requested invoice couldn't be downloaded. Please try again later.
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}