/**********************************************************/
/********************** TEMPLATE ***********************/
/**********************************************************/
    
export default class ActionTemplate {
    // eslint-disable-next-line
    constructor() {}
    
    request(fragment){
        return {
            type: fragment + '_REQUEST',
        };
    }
  
    success(fragment, data){
        return {
            type: fragment + '_SUCCESS',
            data: data,
        };
    }
  
    failure(fragment, exception){
        return {
            type: fragment + '_FAILURE',
            exception: exception,
        };
    }
}