import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CorelogicLogoLine from '../image/Line.svg';
import { LoginAction } from '../controller/actions';
import { selectedAccount, pushToLandingPage, userHasMultipleAccount } from 'controller/services/accountService';
import accountSelectionPageStyles from './styles/AccountSelectionPage';
import ProgressLoader from './components/reusables/ProgressLoader';
import { SegmentAnalyticsService } from '../controller/services';

export default function AccountSelectionPage() {
    const classes = accountSelectionPageStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [accounts, setAccounts] = useState([]);

    const loginUser = useSelector((state) => state.login);

    const [profile] = useState(loginUser.profile);

    useEffect(() => {
        new SegmentAnalyticsService().trackLoadPage();
    }, []);

    useEffect(() => {
        if (loginUser.profile)
            pushToLandingPage(loginUser, history);
    }, [loginUser.profile]);

    useEffect(() => {
        if (userHasMultipleAccount(loginUser))
            setAccounts([...profile.accounts]);
    }, [loginUser.profile?.accounts]);

    useEffect(() => {
        if (!profile) dispatch(new LoginAction().redirectToLoginPage());
    }, [profile]);

    return (
        <div>
            { 
                loginUser.profile &&
                <div className={classes.pageContainer}>
                    <Container className={classes.logoContainer}>
                        <div className={classes.logoBrand}>

                        </div>
                        <img
                            alt="Line"
                            className={classes.logoLine}
                            src={CorelogicLogoLine}
                        />
                        <h1 className={classes.logoText}>RP Data Account Portal</h1>
                    </Container>
                    <Box mt={3} mb={4} className={classes.heading}>Select an account:</Box>
                    <Box>
                        {
                            accounts?.map((account, index) => (
                                <List m={3} component="nav" className={classes.list} onClick={() => selectedAccount(account.accountDetails)} key={index}>
                                    <ListItem button>
                                        <ListItemText 
                                            classes={{ primary: classes.primaryText, secondary: classes.secondaryText }}
                                            primary={account.accountDetails?.tradingName} 
                                            secondary={
                                                `${account.accountDetails?.externalAcctId} ${account.status !== 'ACTIVE' ? <span className="inactive">(INACTIVE)</span> : ''}`
                                            }
                                        />
                                        <ChevronRightIcon className={classes.chevron} />
                                    </ListItem>
                                </List>
                            ))
                        }
                    </Box>
                    <a rel="noreferrer" href="https://auhelpcentre.corelogic.com.au/hc/en-au/sections/4404892320015-RP-Data-Account-Portal" target="_blank">Don't see the account you want?</a>
                </div>
            }
            {
                !loginUser.profile && 
                <div className="body-wrapper">
                    <ProgressLoader text="Redirecting to login page..." />
                </div>
            }
        </div>
    );
}