import ApiService from '../services/api';
import ActionTemplate from './services/actionTemplate';

const GET_ACCOUNT_AND_CONTACT = 'GET_ACCOUNT_AND_CONTACT';
const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
const UPDATE_CONTACT = 'UPDATE_CONTACT';
const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD';
const GET_INVOICES = 'GET_INVOICES';
const CREATE_TOKEN = 'CREATE_TOKEN';
const SET_PERMISSIONS = 'SET_PERMISSIONS';
const RESET_TOKEN = 'RESET_TOKEN';
const SET_PAYMENT_CHANGE_INFO = 'SET_PAYMENT_CHANGE_INFO';

/** Actions for Customer */
export default class CustomerAction {

    constructor() {
        this.api = new ApiService();
        this.actionTemplate = new ActionTemplate();
    }

    getAccountAndContact(){
        return (dispatch) => {
            dispatch(this.actionTemplate.request(GET_ACCOUNT_AND_CONTACT));
            const url = '/user/click/profile';
            return this.api.get(url)
                .then((response) => dispatch(this.actionTemplate.success(GET_ACCOUNT_AND_CONTACT, response.data)))
                .then(() => {
                    dispatch(this.actionTemplate.success(SET_PERMISSIONS));
                })
                .catch((err) => {
                    /* istanbul ignore next */
                    dispatch(this.actionTemplate.failure(GET_ACCOUNT_AND_CONTACT, err));
                });
        };
    }

    updateAccount(request) {
        return (dispatch) => {
            dispatch(this.actionTemplate.request(UPDATE_ACCOUNT));
            const url = '/user/click/account';
            return this.api.patch(url, request)
                .then((response) => {
                    if (response.data)
                        dispatch(this.actionTemplate.success(UPDATE_ACCOUNT, response.data));
                    else
                        /* istanbul ignore next */
                        dispatch(this.actionTemplate.failure(UPDATE_ACCOUNT, 'Failed to update account'));

                })
                .catch((err) => {
                    /* istanbul ignore next */
                    dispatch(this.actionTemplate.failure(UPDATE_ACCOUNT, err));
                });
        };
    }

    updateContact(request) {
        return (dispatch) => {
            dispatch(this.actionTemplate.request(UPDATE_CONTACT));
            const url = '/user/click/contact';
            return this.api.patch(url, request)
                .then((response) => {
                    if (response.data)
                        dispatch(this.actionTemplate.success(UPDATE_CONTACT, response.data));
                    else
                        /* istanbul ignore next */
                        dispatch(this.actionTemplate.failure(UPDATE_CONTACT, 'Failed to update contact'));

                })
                .catch((err) => {
                    /* istanbul ignore next */
                    dispatch(this.actionTemplate.failure(UPDATE_CONTACT, err));
                });
        };
    }

    getPaymentMethod(accountNumber, payload = {}) {
        return (dispatch) => {
            dispatch(this.actionTemplate.request(GET_PAYMENT_METHOD));
            const url = `/user/accounts/${accountNumber}/payment_method`;
            return this.api.get(url, payload)
                .then((response) => {
                    dispatch(this.actionTemplate.success(GET_PAYMENT_METHOD, response.data));
                })
                .catch((err) => {
                    /* istanbul ignore next */
                    dispatch(this.actionTemplate.failure(GET_PAYMENT_METHOD, err));
                });
        };
    }

    getInvoices(accountNumber) {
        return (dispatch) => {
            dispatch(this.actionTemplate.request(GET_INVOICES));
            const url = `/user/accounts/${accountNumber}/invoices`;
            return this.api.get(url)
                .then((response) => dispatch(this.actionTemplate.success(GET_INVOICES, response.data)))
                .catch((err) => {
                    /* istanbul ignore next */
                    dispatch(this.actionTemplate.failure(GET_INVOICES, err));
                });
        };
    }

    createPaymentToken() {
        return (dispatch) => {
            dispatch(this.actionTemplate.request(CREATE_TOKEN));
            const url = '/payment_link_pxpay';
            return this.api.post(url)
                .then((response) => {
                    if (response.data)
                        dispatch(this.actionTemplate.success(CREATE_TOKEN, response.data));
                    else
                        /* istanbul ignore next */
                        dispatch(this.actionTemplate.failure(CREATE_TOKEN, 'Failed to create payment token'));

                })
                .catch((err) => {
                    /* istanbul ignore next */
                    dispatch(this.actionTemplate.failure(CREATE_TOKEN, err));
                });
        };
    }

    async getInvoice(accountNumber, invoiceId) {
        const url = `/user/accounts/${accountNumber}/invoices/${invoiceId}/download`;
        return await this.api.get(url);
    }

    setPaymentChangeInfo(cta) {
        return (dispatch) => dispatch(this.actionTemplate.success(SET_PAYMENT_CHANGE_INFO, cta));
    }

    resetPaymentTokenReduxState() {
        return (dispatch) => dispatch(this.actionTemplate.request(RESET_TOKEN));
    }

    async paymentMethodUpdated() {
        const url = '/user/accounts/payment_method_updated?region=AU';
        return await this.api.post(url);
    }

    async selectAccount(account) {
        const {accountNumber, accountGuid} = account;
        const url = `/user/accounts/account_selected?accountNumber=${accountNumber}&accountGuid=${accountGuid}`;
        return await this.api.post(url);
    }

}
