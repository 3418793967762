import ApiService from '../services/api';
import ActionTemplate from './services/actionTemplate';

const LOGIN = 'LOGIN';
const UM_PROFILE = 'UM_PROFILE';
const USER_VERIFCATION = 'USER_VERIFCATION';
const USER_SESSION = 'USER_SESSION';
const USER_LOGOUT = 'USER_LOGOUT';
const CHANGE_PWORD = 'CHANGE_PASSWORD';
const SET_ACCOUNT = 'SET_ACCOUNT';

const AU = 'AU';

/** Actions for Login */
export default class LoginAction {

    constructor() {
        this.api = new ApiService();
        this.actionTemplate = new ActionTemplate();
    }

    userExpirySession(){
        return {
            type: 'USER_EXPIRY_SESSION',
        };
    }

    resetCustomerInfo(){
        return {
            type: 'RESET_CUSTOMER',
        };
    }

    /**********************************************************/
    /**********************REDIRECT TO LOGIN***********************/
    /**********************************************************/
    

    redirectToLoginPage() {
        return (dispatch) => {
            dispatch(this.actionTemplate.request(LOGIN));
            const url = '/user/login';
            return this.api.get(url, {country: AU})
                .then((response) => {
                    dispatch(this.userExpirySession());
                    dispatch(this.resetCustomerInfo());
                    dispatch(this.actionTemplate.success(LOGIN, response.data));
                })
                .catch((err) => {
                    /* istanbul ignore next */ 
                    dispatch(this.actionTemplate.failure(LOGIN, err));
                });
        };
    }
    
    userVerification(code){
        return (dispatch) => {
            dispatch(this.actionTemplate.request(USER_VERIFCATION));
            const url = '/user/verify_login';
            return this.api.get(url, {code: code, region: 'AU'})
                .then((response) => dispatch(this.actionTemplate.success(USER_VERIFCATION, response.data)))
                .catch((err) => {
                    /* istanbul ignore next */ 
                    dispatch(this.actionTemplate.failure(USER_VERIFCATION, err));
                });
        };
    }
    
    getUmProfile(){
        return (dispatch) => {
            dispatch(this.actionTemplate.request(UM_PROFILE));
            const url = '/user/um/profile';
            return this.api.get(url)
                .then((response) => dispatch(this.actionTemplate.success(UM_PROFILE, response.data)))
                .catch((err) => {
                    /* istanbul ignore next */ 
                    dispatch(this.actionTemplate.failure(UM_PROFILE, err));
                });
        };
    }
    
    /**********************************************************/
    /**************************LOGOUT**************************/
    /**********************************************************/

    userLogout(){
        return (dispatch) => {
            dispatch(this.actionTemplate.request(USER_LOGOUT));
            const url = '/user/logout';
            return this.api.get(url)
                .then((response) => {
                    dispatch(this.userExpirySession());
                    dispatch(this.resetCustomerInfo());
                    dispatch(this.actionTemplate.success(USER_LOGOUT, response.data));
                })
                .catch((err) => {
                    dispatch(this.actionTemplate.failure(USER_SESSION, err));
                });
        };
    }

    resetLoaders(){
        return {
            type: 'RESET_LOADERS',
        };
    }

    redirectToChangePasswordPage() {
        return (dispatch) => {
            dispatch(this.actionTemplate.request(CHANGE_PWORD));
            const url = '/user/change_password';
            return this.api.get(url)
                .then((response) => dispatch(this.actionTemplate.success(CHANGE_PWORD, response.data)))
                .catch((err) => {
                    /* istanbul ignore next */ 
                    dispatch(this.actionTemplate.failure(CHANGE_PWORD, err));
                });
        };
    }

    setAccount(account) {
        return (dispatch) => {
            dispatch(this.actionTemplate.success(SET_ACCOUNT, account));
        };
    }
}   