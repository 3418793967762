import React from 'react';

export default function PageNotFound(){

    return (
        <div style={{'padding': '100px 0', height: '100vh'}}>
            <div style={{'margin': '60px auto', padding: '20px'}}>
                <h2>Oops <span style={{'color': 'orange', fontWeight: '700'}}>404</span>!
                The requested url was not found on this server</h2>
            </div>
        </div>
    );
}
