import gql from 'graphql-tag';

const VALIDATOR = {
    phoneAreaCode: { error: false, required: false },
    phoneNumber: { error: false, required: true },
    phoneCountryCode: { error: false, required: true },
    organizationDEOXAccountEmailC: { error: false, required: true },
    reportAddress: {
        addressLine: { error: false, required: true },
        city: { error: false, required: true },
        state: { error: false, required: true },
        singleLineAddress: { error: false, required: true },
        postalCode: { error: false, required: false },
    },
    invoiceAddress: {
        addressLine: { error: false, required: true },
        city: { error: false, required: true },
        state: { error: false, required: true },
        singleLineAddress: { error: false, required: true },
        postalCode: { error: false, required: false },
    },
    primaryContactEmailAddress: { error: false, required: true },
    paymentContactEmailAddress: { error: false, required: false },
};

const FIELD_INFO_TYPES = {
    ACCOUNT_NAME: 'Account Name',
    BUSINESS_NAME: 'Business Name',
    ABN: 'ABN',
    PRIMARY_CONTACT: 'Primary Contact',
    PAYMENT_CONTACT: 'Payments Contact',

};

const SECTION = 'Organisation Account';

const UPDATE_ACCOUNT = gql`
mutation UpdateAccount($input: UpdateAccountDetailsRequest) {
    account: updateAccountDetails(input_: $input){
        organizationDEOXAccountEmailC #email
        phoneNumber #phone number
        phoneAreaCode
        phoneCountryCode 
        organizationDEOXBusinessUnitC #Country check
        primaryContactPartyId #for checking primary contact
        primaryContactName
        organizationDEOXNewContactForPaymentC #payment contact
        organizationDEOXNewContactForPaymentIdC
    } 
  }
`;

const UPDATE_ADDRESS = gql`
mutation UpdateAddress($input: UpdateAddressesRequest) {
    addresses: updateAddress(input_: $input){
        addressLine
        addressNumber
        addressType
        city
        country
        postalCode
        state
    } 
}
`;

const UPDATE_CONTACT = gql`
    mutation UpdateContact($input: UpdateContactRequest) {
        contact: updateContact(input_: $input){
            partyNumber
            emailAddress
        } 
    }   
`;

const CREATE_ADDRESS = gql`
mutation CreateAddress($input: CreateAddressesRequest) {
    addresses: createAddress(input_: $input){
        addressLine
        addressType
        city
        country
        postalCode
        state
    } 
}
`;

export { VALIDATOR, SECTION, UPDATE_ACCOUNT, UPDATE_CONTACT, UPDATE_ADDRESS, CREATE_ADDRESS, FIELD_INFO_TYPES };