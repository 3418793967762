import React from 'react';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const showLabelSkelton = () => <Box mb={1}><Skeleton variant="text" width={'20%'} height={24} /></Box>;

const readOnlyTextSkelton = () => <Box mb={1} ml={2}><Skeleton variant="text" width={'100%'} height={24} /></Box>;

const textSkeleton = () => <Box mb={1} mr={2}><Skeleton variant="text" width={'100%'} height={24} /></Box>;

const textFieldSkeleton = () => <Box mb={1}><Skeleton className="text-field-skeleton" variant="text" width={'100%'} height={56} /></Box>;

const addressFieldSkeleton = () => <Box mb={1}><Skeleton className="text-field-skeleton" variant="text" width={'55%'} height={56} /></Box>;

export {
    showLabelSkelton,
    readOnlyTextSkelton,
    textSkeleton,
    textFieldSkeleton,
    addressFieldSkeleton,
};